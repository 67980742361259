import * as React from 'react';
import InputType from "../Models/InputType";

export class PanelInputProps {
    public constructor(
        public inputType: InputType,
        public label: string,
        public name: string,
        public value: any,
        public onChange: (newValue: any) => void,
        public index?: number,
        public onDelete?: (index: number) => void,
        public hoverTitle?: string,
        public placeholder?: string,
        public options?: string[],
        public numberUnit?: string,
        public isIcon?: boolean,
        public min?: number,
        public max?: number
    ) { }
}

export default class PanelInput extends React.Component<PanelInputProps> {
    constructor(props: PanelInputProps) {
        super(props);

        window.addEventListener("resize", () => {
            this.setState(this.state)
        });
    }

    private handleChangeEvent = () => {
        let indexedName = this.props.index !== undefined ? this.props.name + "-" + this.props.index : this.props.name;
        let input: HTMLInputElement = document.getElementById(indexedName) as HTMLInputElement;

        if (this.props.inputType === InputType.boolean) {
            this.props.onChange((input as any).checked); 
        }
        else {
            this.props.onChange(input.value);
        }
    }

    private handleDeleteEvent = () => {
        if (this.props.onDelete && this.props.index !== null && this.props.index !== undefined)
        {
            this.props.onDelete(this.props.index)
        }
    }

    private getOptionsHtml = () => {
        if (this.props.options === undefined) {
            return [];
        }

        let result: JSX.Element[] = [];
        result.push((<option key="-1" value="">Unselected</option>));

        for (let key of this.props.options) {
            if (this.props.isIcon) {
                result.push(
                    <option key={key} value={key} />
                );
            }
            else {
                result.push(<option key={key} value={key} />);
            }
        }

        return result;
    }

    private generateLabel = () => {
        return (
            <label htmlFor={this.props.name}>
                {this.props.label}
            </label>
        )
    }

    private getDeleteButton = () => {
        if (this.props.onDelete) {
            return (<button className="delete-button"
                style={{ width: "2.5em", marginTop: "-0.2em"}}
                type="button"
                onClick={this.handleDeleteEvent} >
                x
            </button>)
        }
        else {
            return (<></>)
        }
    }

    private generateInput = () => {
        let indexedName: string = this.props.index === undefined
            ? this.props.name
            : this.props.name + "-" + this.props.index; 

        switch (this.props.inputType) {
            case InputType.text:
                return (
                    <input name={this.props.name}
                        id={indexedName}
                        title={this.props.hoverTitle}
                        placeholder={this.props.placeholder}
                        type="text"
                        autoComplete="off"
                        value={this.props.value || ''}
                        onChange={this.handleChangeEvent} />
                )
            case InputType.multilineText:
                return (
                    <textarea name={this.props.name}
                        id={indexedName}
                        title={this.props.hoverTitle}
                        placeholder={this.props.placeholder}
                        autoComplete="off"
                        value={this.props.value || ''}
                        onChange={this.handleChangeEvent} />
                )
            case InputType.number:
                return (
                    <>
                        <input name={this.props.name}
                            id={indexedName}
                            title={this.props.hoverTitle}
                            placeholder={this.props.placeholder}
                            type="number"
                            step="any"
                            value={this.props.value || 0}
                            onChange={this.handleChangeEvent} /><span>{this.props.numberUnit}</span>
                    </>
                )
            case InputType.color:
                return (
                    <>
                        <input name={this.props.name}
                            id={indexedName}
                            title={this.props.hoverTitle}
                            placeholder={this.props.placeholder}
                            type="color"
                            value={this.props.value || '#000000'}
                            onChange={this.handleChangeEvent} />
                        <span className="color-code">
                            {this.props.value}
                        </span>
                    </>
                )
            case InputType.typeAhead:
                return (
                    <>
                        <input name={this.props.name}
                            id={indexedName}
                            title={this.props.hoverTitle}
                            list={indexedName + "-options"}
                            value={this.props.value ?? ""}
                            onClick={(e) => e.currentTarget.select()}
                            onBlur={(e) => e.target.value = this.props.value}
                            onChange={this.handleChangeEvent} />
                        <datalist id={indexedName + "-options"} >
                            {this.getOptionsHtml()}
                        </datalist>
                        { this.getDeleteButton()}
                    </>
                )
            case InputType.boolean:
                return (                            
                    <input name={this.props.name}
                        id={indexedName}
                        type="checkbox"
                        title={this.props.hoverTitle}
                        value="enabled"
                        checked={this.props.value === true}
                        onChange={this.handleChangeEvent} />)
            case InputType.range:
                return (<>
                    <input name={this.props.name}
                        id={indexedName}
                        title={this.props.hoverTitle}
                        placeholder={this.props.placeholder}
                        type="range"
                        step="1"
                        min={this.props.min}
                        max={this.props.max}
                        value={this.props.value ?? 0}
                        onChange={this.handleChangeEvent} />
                    <span>{this.props.value} {this.props.numberUnit}</span>
                </>)
        }
    }

    render() {
        return (
            <div className="input inline-input">
                {this.generateLabel()}
                {this.generateInput()}
            </div>
        )
    }
}