import * as signalR from '@microsoft/signalr';
import { HubConnectionState } from '@microsoft/signalr';
import * as React from 'react';
import { CSSProperties, ReactElement } from 'react';
import './App.css';
import Ad from './Components/Ad';
import ButtonPanelButton from './Components/ButtonPanelButton';
import ButtonPanelGroup from './Components/ButtonPanelGroup';
import ButtonPropertiesPanel from './Components/ButtonPropertiesPanel';
import ConsoleInfo from './Components/ConsoleName';
import ConsolePropertiesPanel from './Components/ConsolePropertiesPanel';
import DefaultViewIndicator from './Components/DefaultViewIndicator';
import Draggable from './Components/Draggable';
import GroupPropertiesPanel from './Components/GroupPropertiesPanel';
import Menu from './Components/Menu';
import PropertiesPanel from './Components/PropertiesPanel';
import Toolbar from './Components/Toolbar';
import UserBadge from './Components/UserBadge';
import ButtonEventType from './Enums/ButtonEventType';
import AppState from './Models/AppState';
import ButtonEvent from './Models/ButtonEvent';
import ConsoleButton from './Models/ConsoleButton';
import { DragPosition } from './Models/DragPosition';
import Group from './Models/Group';
import LocalStorageService from './Models/LocalStorageService';
import LoginService from './Models/LoginService';
import PanelTab from './Models/PanelTab';
import ToolType from './Models/ToolType';
import IconService from './IconService';

export let GlobalAppState: AppState = new AppState();

class App extends React.Component<{}, AppState>  {
    buttonHub: signalR.HubConnection | null = null;

    selectionBox: ReactElement = <></>;

    constructor(_: {}) {
        super(_);

        console.log(IconService.generateIconOptions());

        if (!this.state) {
            this.state = this.loadLocalStorage();

            if (this.state.user !== undefined && this.state.user !== null && this.state.user.email !== "") {
                LoginService.isValid(this.state.user)
                    .then(isValid => {
                        let newState = this.state.clone();
                        newState.user = isValid ? this.state.user : undefined;
                        this.setState(newState);
                    });
            }
        }

        this.state.viewProperties.isPortraitMode = window.innerHeight > window.innerWidth;
    }

    componentDidMount() {
        document.title = "Remote Consoles - " + this.state.currentConsole.name;

        document.addEventListener("keydown", this.handleKeyPress);
        document.addEventListener("keyup", this.handleKeyPress);

        window.addEventListener("beforeunload", (ev) => {
            this.buttonHub?.send("unsubscribeFrom", this.state.clientKey);
        });


        window.addEventListener("resize", this.handleResize);

        this.buttonHub = new signalR.HubConnectionBuilder()
            .withUrl(document.baseURI.substring(0, document.baseURI.length - 1) + "/buttonHub", {
                withCredentials: false
            })
            .build();

        this.buttonHub.on("onError", data => console.log("Error: " + data));

        this.buttonHub.on("onMessage", data => console.log(data));

        this.buttonHub.on("onConnect", data => {
            console.info("Connected");
            this.buttonHub?.send("requestClientKey", this.state.clientKey, false);
        });

        this.buttonHub.on("onAuthenticated", data => {
            window.alert("Authenticated" + data);
        })

        this.buttonHub.on("onKeyProvided", data => {
            console.info("Client Key :", data);
            let newState: AppState = this.state.clone();
            newState.clientKey = data;

            LocalStorageService.saveClientKey(newState.clientKey);

            this.setState(newState);

            if (this.queuedButtons.length > 0) {
                for (let buttonEvent of this.queuedButtons) {
                    this.buttonHub?.invoke("sendButtonEvent", buttonEvent);
                }

                this.queuedButtons = []
            }
        });

        this.buttonHub.on("onSubscribed", data => console.log(data + ": Subscribed"));
        this.buttonHub.on("onUnsubscribed", data => console.log(data + ": Unsubscribed"));
        this.buttonHub.on("onButtonPress", data => console.log("Received Key: " + data));

        this.buttonHub.start();

        LoginService.isStandalone()
            .then((result: boolean) => {
                let newState = this.state.clone();

                newState.isStandalone = result;

                this.setState(newState);
            })
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyPress);
        document.removeEventListener("keyup", this.handleKeyPress);

        window.removeEventListener("resize", this.handleResize);

        this.buttonHub?.stop();
    }

    render() {
        let orientation = 'landscape';

        if (this.state.viewProperties.isPortraitMode) {
            orientation = 'portrait';
        }

        GlobalAppState = this.state.clone();

        return (
            <div className={"main-container " + orientation}>
                {this.state.isStandalone
                    ? <></>
                    : <Ad user={this.state.user} />
                }

                <div className="app-content">
                    <Menu isEditing={this.state.viewProperties.isEditMode}
                        onEditClicked={this.toggleEdit}
                        isStandalone={this.state.isStandalone}
                    />

                    <Toolbar onToolSelected={this.handleToolSelection} />

                    <div className="top-right-elements">
                        {this.state.isStandalone
                            ? <></>
                            : <UserBadge user={this.state.user} />}

                        <ConsoleInfo onNameChange={this.updateName} />
                    </div>

                    <PropertiesPanel onTabChange={(t) => this.setState(this.state.updateSelectedTab(t))}
                        onToggle={this.togglePropertiesPanel}>
                        <ButtonPropertiesPanel key={PanelTab.Selected}
                            header={"Selected Button"}
                            isShown={this.state.getSelectedButton() !== null}
                            onButtonUpdate={(b) => this.setState(this.state.updateButton(b))}
                            onButtonDelete={(b) => this.setState(this.state.deleteButton(b))}
                            onButtonDuplicate={(b) => this.setState(this.state.duplicateButton(b))} />

                        <GroupPropertiesPanel key={PanelTab.Group}
                            header={"Button Group"}
                            isShown={this.state.getSelectedButtons().length > 1}
                            onGroupUpdate={(g) => this.setState(this.state.updateGroup(g))}
                            onGroupCreate={() => this.setState(this.state.createGroup())}
                            onUngroup={() => this.setState(this.state.ungroupSelected())}
                            onGroupDelete={(g) => this.setState(this.state.deleteGroup(g))}
                            onButtonSelect={(b) => this.setState(this.state.setLastSelectedButton(b))} />

                        <ConsolePropertiesPanel key={PanelTab.Console}
                            isShown={true}
                            header={"Console Display"}
                            onConsolePropertiesUpdate={(console) => this.setState(this.state.updateConsole(console))} />

                    </PropertiesPanel>

                    {this.selectionBox}

                    <Draggable className={(this.state.viewProperties.isEditMode) ? "button-panel open-properties" : "button-panel"}
                        style={this.generateBackgroundStyle()}
                        onDraggableMove={this.handlePanelDrag}
                        onBackgroundClick={() => this.setState(this.state.deselectAll())}
                        allowWheelEvents={true}>
                        <DefaultViewIndicator user={this.state.user}  />


                        <div className="button-container"
                            style={{
                                left: this.state.viewProperties.offsetX + "px",
                                top: this.state.viewProperties.offsetY + "px",
                                zoom: this.state.viewProperties.zoom
                            }}>
                            {this.state.currentConsole.buttons.map(button => (
                                <ButtonPanelButton key={button.key}
                                    buttonInfo={button}
                                    gridSize={this.state.currentConsole.gridSize}
                                    showHandles={this.state.viewProperties.isEditMode}
                                    snapToGrid={this.state.currentConsole.snapToGrid}
                                    isLastSelected={this.state.viewProperties.lastSelected === button.key}
                                    onSizeUpdated={(b) => this.setState(this.state.updateButton(b))}
                                    onActivate={this.activateButton}
                                    onDectivate={this.deactivateButton} />
                            ))}

                            {this.state.currentConsole.groups.map(group => (
                                <ButtonPanelGroup key={group.key}
                                    group={group}
                                    onSizeUpdated={(g, b) => this.setState(this.state.updateGroupBounds(g, b))}
                                    onSelected={(g) => this.setState(this.state.selectGroup(g))} />
                            ))}

                            {this.generateSelectionGroup()}

                        </div>
                    </Draggable>
                </div>
            </div>
        );
    }

    generateSelectionGroup = () => {
        let selectedButtons: ConsoleButton[] = this.state.getSelectedButtons();

        if (selectedButtons.length > 1 && !this.state.isEntireGroupSelected(selectedButtons[0].group)) {
            return <>
                <ButtonPanelGroup key={-2}
                    group={new Group(-2)}
                    onSizeUpdated={(g, b) => this.setState(this.state.updateGroupBounds(g, b))}
                    onSelected={(_) => _} />
            </>
        }
        else {
            return <></>;
        }
    }

    handleResize = (_: UIEvent) => {
        let newState: AppState = this.state.clone();

        newState.viewProperties.isPortraitMode = window.innerHeight > window.innerWidth || window.innerWidth < 740;

        // Ensures portrait and landscape classes are set (needs refactor - should be set higher)
        this.setState(newState);
    }

    queuedButtons: ButtonEvent[] = [];

    activateButton = (buttonInfo: ConsoleButton) => {
        if (!this.state.viewProperties.isEditMode) {
            if (this.buttonHub?.state !== HubConnectionState.Connected) {
                this.queuedButtons.push(new ButtonEvent(this.state.clientKey, this.state.currentConsole.name, ButtonEventType.Press, buttonInfo.mapping))
                this.buttonHub?.start();
                return;
            }

            this.buttonHub?.invoke("sendButtonEvent", new ButtonEvent(this.state.clientKey, this.state.currentConsole.name, ButtonEventType.Press, buttonInfo.mapping));
        }
    }

    deactivateButton = (buttonInfo: ConsoleButton) => {
        if (!this.state.viewProperties.isEditMode) {
            if (this.buttonHub?.state !== HubConnectionState.Connected) {
                this.queuedButtons.push(new ButtonEvent(this.state.clientKey, this.state.currentConsole.name, ButtonEventType.Release, buttonInfo.mapping))
                this.buttonHub?.start();
                return;
            }

            this.buttonHub?.invoke("sendButtonEvent", new ButtonEvent(this.state.clientKey, this.state.currentConsole.name, ButtonEventType.Release, buttonInfo.mapping));
        }
        else if (!this.state.isSelecting()) {
            this.setState(this.state.selectButton(buttonInfo));
        }
    }

    toggleEdit = () => {
        let newState: AppState = this.state.clone();
        newState.viewProperties.isEditMode = !newState.viewProperties.isEditMode;

        if (!newState.viewProperties.isEditMode) {
            newState.deselectAll();
            newState.viewProperties.isMultiSelectEnabled = false;
            this.saveLocalStorage(newState);
        }
        else {
            LocalStorageService.saveViewProperties(newState.viewProperties);
        }

        this.setState(newState);
    }

    togglePropertiesPanel = () => {
        let newState: AppState = this.state.clone();

        newState.viewProperties.showProperties = !newState.viewProperties.showProperties;

        this.setState(newState);
    }

    handleToolSelection = (selectedTool: ToolType) => {
        let newState: AppState = this.state.clone();

        switch (selectedTool) {
            case ToolType.Add:
                newState.generateButtonInCurrentView();
                break;
            case ToolType.Pan:
                newState.viewProperties.isMultiSelectEnabled = false;
                break;
            case ToolType.Select:
                newState.viewProperties.isMultiSelectEnabled = true;
                break;
            case ToolType.Recenter:
                newState.recenter();
                break;
            case ToolType.LockViewport:
                newState.viewProperties.viewLocked = false;
                break;
            case ToolType.UnlockViewport:
                newState.viewProperties.viewLocked = true;
                break;
            default:
                console.error("Unhandled tool selected");
                return;
        }

        this.setState(newState);
    }

    handleKeyPress = (e: KeyboardEvent) => {
        if (this.state.viewProperties.shiftHeld !== e.shiftKey) {
            let newState: AppState = this.state.clone();
            newState.viewProperties.shiftHeld = e.shiftKey;
            this.setState(newState);
        }
    }

    handlePanelDrag = (e: DragPosition) => {
        let newState: AppState = this.state.clone();

        if (!this.state.viewProperties.viewLocked && (e.getScaleOffset() !== 0 || (!this.state.isSelecting() && (e.getXOffset() !== 0 || e.getYOffset() !== 0)))) {
            this.selectionBox = <></>;
            newState.applyZoomAndPan(e);
            this.setState(newState);
        }
        else if (this.state.isSelecting()) {
            this.selectionBox = newState.applySelection(e);
            this.setState(newState);
        }
        else if (e.getScaleOffset() !== 0) {
            this.setState(newState);
        }
    }

    saveLocalStorage = (newState: AppState) => {
        LocalStorageService.saveClientKey(newState.clientKey);
        LocalStorageService.saveUser(newState.user ?? undefined);
        LocalStorageService.saveCurrentConsole(newState.currentConsole);
        LocalStorageService.saveViewProperties(newState.viewProperties);
    }

    loadLocalStorage = (): AppState => {
        return new AppState({
            clientKey: LocalStorageService.loadClientKey(),
            user: LocalStorageService.loadUser(),
            currentConsole: LocalStorageService.loadCurrentConsole(),
            savedConsoles: LocalStorageService.loadSavedConsoles(),
            viewProperties: LocalStorageService.loadViewProperties()
        } as AppState);
    }

    updateName = (newName: string) => {
        let newState: AppState = this.state.clone();
        newState.currentConsole.name = newName ?? "Untitled Console";

        document.title = "Remote Consoles - " + newName;

        this.setState(newState);
    }

    private generateBackgroundStyle(): CSSProperties {
        let bgStyle: CSSProperties = {
            backgroundColor: this.state.currentConsole.backgroundColor
        };

        if (this.state.viewProperties.isEditMode && this.state.currentConsole.showGrid) {
            bgStyle.backgroundPositionX = this.state.viewProperties.offsetX * this.state.viewProperties.zoom + "px";
            bgStyle.backgroundPositionY = this.state.viewProperties.offsetY * this.state.viewProperties.zoom + "px";
            bgStyle.backgroundImage = "linear-gradient(to right, rgba(255,255,255,0.125) 1px, transparent 1px), linear-gradient(to bottom, rgba(255,255,255,0.125)  1px, transparent 1px)";
            let bgSize = this.state.currentConsole.gridSize * this.state.viewProperties.zoom
            bgStyle.backgroundSize = bgSize + "px " + bgSize + "px";
        }

        return bgStyle;
    }
}

export default App;