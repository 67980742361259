import { GoogleCredentialResponse, GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import * as React from "react";
import Shop from "../Components/Shop";
import SimplePageTemplate from "../Components/SimplePageTemplate";
import LocalStorageService from "../Models/LocalStorageService";
import LoginHost from "../Models/LoginHost";
import LoginService from "../Models/LoginService";
import User from "../Models/User";
import './Account.css';

export class AccountState {
    user?: User;
    hasBeenValidated: boolean = false;
    isValid: boolean = false;


    constructor(user?: User) {
        this.user = user;
    }

    isLoggedIn = (): boolean => {
        return this.user !== undefined && this.isValid;
    }
}

export default class Account extends React.Component<{}, AccountState> {

    constructor(_: {}) {
        super(_);

        this.state = new AccountState(LocalStorageService.loadUser())

        LoginService.isValid(this.state.user)
            .then((isValid: boolean) => {
                let newState: AccountState = Object.assign({}, this.state);

                newState.hasBeenValidated = true;
                newState.isValid = isValid;

                this.setState(newState);
            });
    }

    render() {
        return (
            <SimplePageTemplate pageIcon="user" pageTitle={"Account"} >
                {(this.state.user === undefined)
                    ? this.logInScreen()
                    : (!this.state.hasBeenValidated)
                        ? this.awaitingValidationScreen()
                        : (!this.state.isValid) 
                            ? this.retryLoginScreen()
                            : this.shopAndLogoutScreen()
                }
            </SimplePageTemplate>
        )
    }

    private shopAndLogoutScreen = (): React.JSX.Element => {
        if (this.state.user === undefined) {
            return <>
                <h3>Error: Unable to show shop and logout screen (user information missing)</h3>
                <p>
                    Try refreshing this page. If the issue persusts, please contact <a href="https://shiggles.co/support">Shiggles LLC</a>.
                </p>
            </>
        }

        return <>
            <h1>
                Logged-in as {this.state.user?.displayName} ({this.state.user?.email})
            </h1>
            <div className="section">
                <button className="log-out" onClick={this.logOut}>Log Out</button>
            </div>

            <hr />

            <h1>Purchases / Subscriptions</h1>
            <div className="section">
                <Shop user={this.state.user} />
            </div>
        </>;
    }

    private logInScreen = (): React.JSX.Element => {
        // <h1>Sign-in to remove ads or purchase the standalone edition</h1>
        return <>
            <h1>Sign-in to purchase the standalone edition</h1>
            
            <p>
                Purchased software will be linked to the email address associated with the account selected below.
                If the selected account's email is changed after purchase, you will lose access to purchased items until the email address is reverted to its original value (or until items are re-purchased under the new email address).
            </p>

            {this.logInOptions()}
        </>
    }

    private logInOptions = (): React.JSX.Element => {
        return <>
            <h2>Third party sign-in only at this time</h2>
            <GoogleLogin onSuccess={this.onGoogleLogin} data-width="200" />

            <p>More coming soon!</p>
        </>
    }

    private awaitingValidationScreen = (): React.JSX.Element => {
        return <>
            <h1>Validating credentials</h1>

            <p>
                Please wait while we get you logged in...
            </p>
        </>
    }

    private retryLoginScreen = (): React.JSX.Element => {
        return <>
            <h1>Unable to log you in using the stored credentials. Try logging in again.</h1>
            <em>Ensure you pick the same account every time. Purchases are associated to this account's email address.</em>

            <p>
                Please wait while we get you logged in...
            </p>

            {this.logInOptions()}
        </>
    }

    onGoogleLogin = (goog: GoogleCredentialResponse) => {
        let responsePayload: any = jwtDecode(goog.credential ?? "");

        let newUser: User | undefined = goog.credential
            ? new User({
                email: responsePayload.email,
                displayName: responsePayload.name,
                image: responsePayload.picture,
                credentials: goog.credential,
                loginHost: LoginHost.Google
            } as User)
            : undefined;

        this.onAllLogins(newUser);
    }

    onAllLogins = (user?: User) => {
        if (user === undefined) {
            LocalStorageService.saveUser(undefined);
            document.location.href = "/account"
            return;
        }

        LoginService.isValid(user)
            .then((isValid) => {
                if (isValid) {
                    LocalStorageService.saveUser(user);
                    document.location.href = "/account"
                }
                else {
                    LocalStorageService.saveUser(undefined);
                    document.location.href = "/account"
                }
            });
    }

    onError = (error: any) => {
        console.log(error);
    }

    logOut = () => {
        LocalStorageService.saveUser(undefined);

        document.location.href = "/account";
    }
}