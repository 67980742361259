import PanelTab from "./PanelTab";

export default class ViewProperties {
    public lastSelected: number = -1;
    public isEditMode: boolean = true;
    public isMultiSelectEnabled: boolean = false;
    public shiftHeld: boolean = false;
    public currentPropertiesTab: PanelTab = PanelTab.Console;
    public viewLocked: boolean = false;
    public offsetX: number = 0;
    public offsetY: number = 0;
    public zoom: number = 1;
    public showProperties: boolean = true;
    public isPortraitMode: boolean = false;

    constructor(viewProperties?: ViewProperties) {
        if (viewProperties) {
            this.lastSelected = viewProperties.lastSelected;
            this.isEditMode = viewProperties.isEditMode;
            this.isMultiSelectEnabled = viewProperties.isMultiSelectEnabled;
            this.shiftHeld = viewProperties.shiftHeld;
            this.currentPropertiesTab = viewProperties.currentPropertiesTab;
            this.viewLocked = viewProperties.viewLocked;
            this.offsetX = viewProperties.offsetX;
            this.offsetY = viewProperties.offsetY;
            this.zoom = viewProperties.zoom;
            this.showProperties = viewProperties.showProperties;
            this.isPortraitMode = viewProperties.isPortraitMode;
        }
    }

    public clone() {
        return new ViewProperties({
            lastSelected: this.lastSelected,
            isEditMode: this.isEditMode,
            isMultiSelectEnabled: this.isMultiSelectEnabled,
            shiftHeld: this.shiftHeld,
            currentPropertiesTab: this.currentPropertiesTab,
            viewLocked: this.viewLocked,
            offsetX: this.offsetX,
            offsetY: this.offsetY,
            zoom: this.zoom,
            showProperties: this.showProperties,
            isPortraitMode: this.isPortraitMode
        } as ViewProperties)
    }
}