import * as React from 'react';
import { GlobalAppState } from '../App';
import Axis from '../Enums/Axis';
import { DragPosition } from '../Models/DragPosition';
import Group from '../Models/Group';
import './ButtonPanelGroup.css';
import GrabHandle from './GrabHandle';
import Bounds from '../Models/Bounds';
import Defaults from '../Models/Defaults';

interface IButtonPanelGroupProps {
    group: Group;
    onSizeUpdated: (group: Group, bounds: Bounds) => void;
    onSelected: (group: Group) => void;
}

class ButtonPanelGroup extends React.Component<IButtonPanelGroupProps> {

    render() {
        let groupBounds: Bounds = GlobalAppState.getGroupBounds(this.props.group.key);

        let groupClass: string = "group";

        if (this.props.group.key === -2) {
            groupClass += " temporary";
        }
        else if (!this.props.group.showGroup) {
            groupClass += " invisible";
        }

        return (
            <>
                <div className={groupClass}
                    style={{
                        top: groupBounds.top - this.props.group.padding + "px",
                        left: groupBounds.left - this.props.group.padding + "px",
                        height: this.getHeight(groupBounds) + (this.props.group.padding * 2) + "px",
                        width: this.getWidth(groupBounds) + (this.props.group.padding * 2) + "px",
                        zIndex: (groupBounds.top - 10 + groupBounds.left - 10) * 10,
                        backgroundColor: (this.props.group.key === -2) ? 'transparent' : this.props.group.backgroundColor,
                        color: this.props.group.color,
                        borderColor: this.props.group.color
                    }}
                    onTouchEndCapture={this.onSelected}
                    onMouseUp={this.onSelected}
                    onContextMenu={(e) => { e.preventDefault(); }} >
                    <div className="group-title"
                        title={this.props.group.name}
                        style={{
                            backgroundColor: this.props.group.backgroundColor,
                            borderColor: this.props.group.color
                        }}>
                        {this.props.group.name}
                    </div>

                    {this.getGrabhandles(groupBounds)}
                </div>
            </>
        )
    }

    getGrabhandles = (groupBounds: Bounds) => {
        let offset = Defaults.GRAB_HANDLE_SIZE / 2;

        if (GlobalAppState.viewProperties.isEditMode
            && (GlobalAppState.isEntireGroupSelected(this.props.group.key) || this.props.group.key === -2)) {
            return (
                <>
                    <GrabHandle className="corner"
                        onDrag={this.updateTopLeft}
                        x={0 - offset}
                        y={0 - offset} />
                    <GrabHandle className="corner"
                        onDrag={this.updateTopRight}
                        x={this.getWidth(groupBounds) + (this.props.group.padding * 2) + offset}
                        y={0 - offset} />
                    <GrabHandle className="corner"
                        onDrag={this.updateBottomLeft}
                        x={0 - offset}
                        y={this.getHeight(groupBounds) + (this.props.group.padding * 2) + offset} />
                    <GrabHandle className="corner"
                        onDrag={this.updateBottomRight}
                        x={this.getWidth(groupBounds) + (this.props.group.padding * 2) + offset}
                        y={this.getHeight(groupBounds) + (this.props.group.padding * 2) + offset} />

                    <GrabHandle className="center"
                        onDrag={this.updatePosition}
                        x={(this.getWidth(groupBounds) + this.props.group.padding * 2) / 2}
                        y={-this.props.group.padding * 2 - offset * 2} />

                    <GrabHandle className="side"
                        onDrag={this.updateTop}
                        restrictaxis={Axis.y}
                        x={(this.getWidth(groupBounds) + this.props.group.padding * 2) / 2}
                        y={0 - offset} />
                    <GrabHandle className="side"
                        onDrag={this.updateBottom}
                        restrictaxis={Axis.y}
                        x={(this.getWidth(groupBounds) + this.props.group.padding * 2) / 2}
                        y={this.getHeight(groupBounds) + (this.props.group.padding * 2) + offset} />
                    <GrabHandle className="side"
                        onDrag={this.updateLeft}
                        restrictaxis={Axis.x}
                        x={0 - offset}
                        y={(this.getHeight(groupBounds) + this.props.group.padding * 2) / 2} />
                    <GrabHandle className="side"
                        onDrag={this.updateRight}
                        restrictaxis={Axis.x}
                        x={this.getWidth(groupBounds) + (this.props.group.padding * 2) + offset}
                        y={(this.getHeight(groupBounds) + this.props.group.padding * 2) / 2} />
                </>
            );
        }
        else {
            return (<></>)
        }
    }

    getWidth = (buttonInfo: Bounds): number => {
        return buttonInfo.right - buttonInfo.left;
    }

    getHeight = (buttonInfo: Bounds): number => {
        return buttonInfo.bottom - buttonInfo.top;
    }

    getMiddleX = (buttonInfo: Bounds): number => {
        return buttonInfo.left + (this.getWidth(buttonInfo)/2);
    }

    getMiddleY = (buttonInfo: Bounds): number => {
        return buttonInfo.top + (this.getHeight(buttonInfo)/2);
    }

    updateTopLeft = (e: DragPosition) => {
        let currentBounds: Bounds = GlobalAppState.getGroupBounds(this.props.group.key);
        let newBounds = Object.assign({}, currentBounds);

        newBounds.top = currentBounds.top + e.getYOffset();
        newBounds.left = currentBounds.left + e.getXOffset();

        if (e.isReleased && GlobalAppState.currentConsole.snapToGrid) {
            newBounds.top = this.snapToGrid(newBounds.top);
            newBounds.left = this.snapToGrid(newBounds.left);
        }

        this.props.onSizeUpdated(this.props.group, newBounds);
    }

    updateTopRight = (e: DragPosition) => {
        let currentBounds: Bounds = GlobalAppState.getGroupBounds(this.props.group.key);
        let newBounds = Object.assign({}, currentBounds);

        newBounds.top = currentBounds.top + e.getYOffset();
        newBounds.right = currentBounds.right + e.getXOffset();

        if (e.isReleased && GlobalAppState.currentConsole.snapToGrid) {
            newBounds.top = this.snapToGrid(newBounds.top);
            newBounds.right = this.snapToGrid(newBounds.right);
        }

        this.props.onSizeUpdated(this.props.group, newBounds);
    }

    updateBottomLeft = (e: DragPosition) => {
        let currentBounds: Bounds = GlobalAppState.getGroupBounds(this.props.group.key);
        let newBounds = Object.assign({}, currentBounds);

        newBounds.bottom = currentBounds.bottom + e.getYOffset();
        newBounds.left = currentBounds.left + e.getXOffset();

        if (e.isReleased  && GlobalAppState.currentConsole.snapToGrid) {
            newBounds.bottom = this.snapToGrid(newBounds.bottom);
            newBounds.left = this.snapToGrid(newBounds.left);
        }

        this.props.onSizeUpdated(this.props.group, newBounds);
    }

    updateBottomRight = (e: DragPosition) => {
        let currentBounds: Bounds = GlobalAppState.getGroupBounds(this.props.group.key);
        let newBjorns = Object.assign({}, currentBounds);

        newBjorns.bottom = currentBounds.bottom + e.getYOffset();
        newBjorns.right = currentBounds.right + e.getXOffset();

        if (e.isReleased && GlobalAppState.currentConsole.snapToGrid) {
            newBjorns.bottom = this.snapToGrid(newBjorns.bottom);
            newBjorns.right = this.snapToGrid(newBjorns.right);
        }

        this.props.onSizeUpdated(this.props.group, newBjorns);
    }

    updateTop = (e: DragPosition) => {
        let currentBounds: Bounds = GlobalAppState.getGroupBounds(this.props.group.key);
        let newBounds = Object.assign({}, currentBounds);

        newBounds.top = currentBounds.top + e.getYOffset();

        if (e.isReleased && GlobalAppState.currentConsole.snapToGrid) {
            newBounds.top = this.snapToGrid(newBounds.top);
        }

        this.props.onSizeUpdated(this.props.group, newBounds);
    }

    updateLeft = (e: DragPosition) => {
        let currentBounds: Bounds = GlobalAppState.getGroupBounds(this.props.group.key);
        let newBounds = Object.assign({}, currentBounds);

        newBounds.left = currentBounds.left + e.getXOffset();

        if(e.isReleased && GlobalAppState.currentConsole.snapToGrid) {
            newBounds.left = this.snapToGrid(newBounds.left);
        }

        this.props.onSizeUpdated(this.props.group, newBounds);
    }

    updateBottom = (e: DragPosition) => {
        let currentBounds: Bounds = GlobalAppState.getGroupBounds(this.props.group.key);
        let newBounds = Object.assign({}, currentBounds);

        newBounds.bottom = currentBounds.bottom + e.getYOffset();

        if(e.isReleased && GlobalAppState.currentConsole.snapToGrid) {
            newBounds.bottom = this.snapToGrid(newBounds.bottom);
        }

        this.props.onSizeUpdated(this.props.group, newBounds);
    }

    updateRight = (e: DragPosition) => {
        let currentBounds: Bounds = GlobalAppState.getGroupBounds(this.props.group.key);
        let newBounds = Object.assign({}, currentBounds);

        newBounds.right += e.getXOffset();

        if (e.isReleased && GlobalAppState.currentConsole.snapToGrid) {
            newBounds.right = this.snapToGrid(newBounds.right);
        }

        this.props.onSizeUpdated(this.props.group, newBounds);
    }

    updatePosition = (e: DragPosition) => {
        let currentBounds: Bounds = GlobalAppState.getGroupBounds(this.props.group.key);
        let newBounds = Object.assign({}, currentBounds);

        newBounds.top += e.getYOffset();
        newBounds.bottom +=  e.getYOffset();
        newBounds.left += e.getXOffset();
        newBounds.right += e.getXOffset();

        if (e.isReleased && GlobalAppState.currentConsole.snapToGrid) {
            newBounds.top = this.snapToGrid(newBounds.top);
            newBounds.right = this.snapToGrid(newBounds.right);
            newBounds.bottom = this.snapToGrid(newBounds.bottom);
            newBounds.left = this.snapToGrid(newBounds.left); 
        }

        this.props.onSizeUpdated(this.props.group, newBounds);
    }

    snapToGrid = (position: number): number => {
        return Math.round(position / GlobalAppState.currentConsole.gridSize) * GlobalAppState.currentConsole.gridSize;
    }
    

    onSelected = (event: React.MouseEvent<HTMLDivElement, MouseEvent> | React.TouchEvent<HTMLDivElement>) => {
        event.preventDefault();

        this.props.onSelected(this.props.group);
    }
    
}

export default ButtonPanelGroup;