import VirtualKeyCode from "../Enums/VirtualKeyCode";

class Mappings {
    public static getMappingOptions(): string[] {
        let mappingOptions: string[] = [];

        for (let key in Mappings.KeyMapping) {
            mappingOptions.push(key);
        }

        return mappingOptions;
    }

    public static isValidCombo(modifier: VirtualKeyCode, mapping: VirtualKeyCode) {
        for (var i = 0; i < Mappings.InvalidCombos.length; i++) {
            if (Mappings.InvalidCombos[i][0] === modifier
                && Mappings.InvalidCombos[i][1] === mapping) {
                return false;
            }
        }

        return true;
    }

    public static KeyMapping: { [key: string]: VirtualKeyCode } = {
        [""]: VirtualKeyCode.None,
        ["A"]: VirtualKeyCode.VK_A,
        ["B"]: VirtualKeyCode.VK_B,
        ["C"]: VirtualKeyCode.VK_C,
        ["D"]: VirtualKeyCode.K_D,
        ["E"]: VirtualKeyCode.VK_E,
        ["F"]: VirtualKeyCode.VK_F,
        ["G"]: VirtualKeyCode.VK_G,
        ["H"]: VirtualKeyCode.VK_H,
        ["I"]: VirtualKeyCode.VK_I,
        ["J"]: VirtualKeyCode.VK_J,
        ["K"]: VirtualKeyCode.VK_K,
        ["L"]: VirtualKeyCode.VK_L,
        ["M"]: VirtualKeyCode.VK_M,
        ["N"]: VirtualKeyCode.VK_N,
        ["O"]: VirtualKeyCode.VK_O,
        ["P"]: VirtualKeyCode.VK_P,
        ["Q"]: VirtualKeyCode.VK_Q,
        ["R"]: VirtualKeyCode.VK_R,
        ["S"]: VirtualKeyCode.VK_S,
        ["T"]: VirtualKeyCode.VK_T,
        ["U"]: VirtualKeyCode.VK_U,
        ["V"]: VirtualKeyCode.VK_V,
        ["W"]: VirtualKeyCode.VK_W,
        ["X"]: VirtualKeyCode.VK_X,
        ["Y"]: VirtualKeyCode.VK_Y,
        ["Z"]: VirtualKeyCode.VK_Z,
        ["1"]: VirtualKeyCode.VK_1,
        ["2"]: VirtualKeyCode.VK_2,
        ["3"]: VirtualKeyCode.VK_3,
        ["4"]: VirtualKeyCode.VK_4,
        ["5"]: VirtualKeyCode.VK_5,
        ["6"]: VirtualKeyCode.VK_6,
        ["7"]: VirtualKeyCode.VK_7,
        ["8"]: VirtualKeyCode.VK_8,
        ["9"]: VirtualKeyCode.VK_9,
        ["0"]: VirtualKeyCode.VK_0,
        [","]: VirtualKeyCode.OEM_COMMA,
        ["."]: VirtualKeyCode.OEM_PERIOD,
        ["/"]: VirtualKeyCode.OEM_2,
        ["`"]: VirtualKeyCode.OEM_3,
        ["-"]: VirtualKeyCode.OEM_MINUS,
        ["="]: VirtualKeyCode.OEM_PLUS,
        ["["]: VirtualKeyCode.OEM_4,
        ["]"]: VirtualKeyCode.OEM_6,
        ["\\"]: VirtualKeyCode.OEM_5,
        ["SPACE"]: VirtualKeyCode.SPACE,
        ["SHIFT"]: VirtualKeyCode.LSHIFT,
        ["CTRL"]: VirtualKeyCode.LCONTROL,
        ["ALT"]: VirtualKeyCode.MENU,
        ["BACKSPACE"]: VirtualKeyCode.BACK,
        ["CAPS LOCK"]: VirtualKeyCode.CAPITAL,
        ["DELETE"]: VirtualKeyCode.DELETE,
        ["DOWN ARROW"]: VirtualKeyCode.DOWN,
        ["END"]: VirtualKeyCode.END,
        ["ENTER"]: VirtualKeyCode.RETURN,
        ["ESC"]: VirtualKeyCode.ESCAPE,
        ["HELP"]: VirtualKeyCode.HELP,
        ["HOME"]: VirtualKeyCode.HOME,
        ["INSERT"]: VirtualKeyCode.INSERT,
        ["LEFT ARROW"]: VirtualKeyCode.LEFT,
        ["NUM LOCK"]: VirtualKeyCode.NUMLOCK,
        ["PAGE DOWN"]: VirtualKeyCode.NEXT,
        ["PAGE UP"]: VirtualKeyCode.PRIOR,
        ["RIGHT ARROW"]: VirtualKeyCode.RIGHT,
        ["SCROLL LOCK"]: VirtualKeyCode.SCROLL,
        ["TAB"]: VirtualKeyCode.TAB,
        ["UP ARROW"]: VirtualKeyCode.UP,
        ["F1"]: VirtualKeyCode.F1,
        ["F2"]: VirtualKeyCode.F2,
        ["F3"]: VirtualKeyCode.F3,
        ["F4"]: VirtualKeyCode.F4,
        ["F5"]: VirtualKeyCode.F5,
        ["F6"]: VirtualKeyCode.F6,
        ["F7"]: VirtualKeyCode.F7,
        ["F8"]: VirtualKeyCode.F8,
        ["F9"]: VirtualKeyCode.F9,
        ["F10"]: VirtualKeyCode.F10,
        ["F11"]: VirtualKeyCode.F11,
        ["F12"]: VirtualKeyCode.F12,
        ["F13"]: VirtualKeyCode.F13,
        ["F14"]: VirtualKeyCode.F14,
        ["F15"]: VirtualKeyCode.F15,
        ["F16"]: VirtualKeyCode.F16,
        ["Numpad add"]: VirtualKeyCode.ADD,
        ["Numpad subtract"]: VirtualKeyCode.SUBTRACT,
        ["Numpad multiply"]: VirtualKeyCode.MULTIPLY,
        ["Keypad divide"]: VirtualKeyCode.DIVIDE,
        ["Numpad 0"]: VirtualKeyCode.NUMPAD0,
        ["Numpad 1"]: VirtualKeyCode.NUMPAD1,
        ["Numpad 2"]: VirtualKeyCode.NUMPAD2,
        ["Numpad 3"]: VirtualKeyCode.NUMPAD3,
        ["Numpad 4"]: VirtualKeyCode.NUMPAD4,
        ["Numpad 5"]: VirtualKeyCode.NUMPAD5,
        ["Numpad 6"]: VirtualKeyCode.NUMPAD6,
        ["Numpad 7"]: VirtualKeyCode.NUMPAD7,
        ["Numpad 8"]: VirtualKeyCode.NUMPAD8,
        ["Numpad 9"]: VirtualKeyCode.NUMPAD9
    };
    
    public static InvalidCombos = [
        [VirtualKeyCode.LSHIFT, VirtualKeyCode.LSHIFT],
        [VirtualKeyCode.LCONTROL, VirtualKeyCode.LCONTROL],
        [VirtualKeyCode.LCONTROL, VirtualKeyCode.TAB],
        [VirtualKeyCode.MENU, VirtualKeyCode.MENU],
        [VirtualKeyCode.MENU, VirtualKeyCode.F4],
        [VirtualKeyCode.MENU, VirtualKeyCode.TAB]
    ];



 
}

export default Mappings