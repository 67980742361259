import ButtonType from "../Enums/ButtonType";
import Bounds from "./Bounds";
import Defaults from "./Defaults";

export default class ConsoleButton extends Bounds {
    public type: ButtonType = ButtonType.Rectangle;

    public text: string = "";
    public selected: boolean = true;
    public backgroundColor: string = Defaults.BUTTON_BACKGROUND_COLOR;
    public borderColor: string = Defaults.BUTTON_FOREGROUND_COLOR;
    public fontSize: number = Defaults.BUTTON_FONT_SIZE;
    public labelOffsetX: number = 0;
    public labelOffsetY: number = 0;
    public mapping: string[] = ["SPACE"];
    public icon: string = "arrow-up";
    public iconSize: number = 60;
    public showIcon: boolean = false;
    public group: number = -1;

    public constructor(public key: number, leftOffset: number = 0, topOffset: number = 0)
    {
        super(
            leftOffset,
            leftOffset + Defaults.BUTTON_SIZE,
            topOffset,
            topOffset + Defaults.BUTTON_SIZE,
        )
    }

    public static fromStub(stub: ConsoleButton): ConsoleButton {
        let newButton = new ConsoleButton(
            stub.key,
            stub.left,
            stub.top
        );

        newButton.right = stub.right;
        newButton.bottom = stub.bottom;
        newButton.text = stub.text;
        newButton.selected = stub.selected;
        newButton.backgroundColor = stub.backgroundColor;
        newButton.borderColor = stub.borderColor;
        newButton.fontSize = stub.fontSize;
        newButton.labelOffsetX = stub.labelOffsetX;
        newButton.labelOffsetY = stub.labelOffsetY;
        newButton.mapping = stub.mapping;
        newButton.icon = stub.icon;
        newButton.iconSize = stub.iconSize;
        newButton.showIcon = stub.showIcon;
        newButton.group = stub.group;

        return newButton;
    }

    public clone(newKey?: number): ConsoleButton {
        if (newKey === undefined) {
            newKey = this.key;
        }

        let newButton = new ConsoleButton(newKey);

        newButton.type = this.type;
        newButton.top = this.top;
        newButton.bottom = this.bottom;
        newButton.left = this.left;
        newButton.right = this.right;
        newButton.text = this.text;
        newButton.selected = this.selected;
        newButton.backgroundColor = this.backgroundColor;
        newButton.borderColor = this.borderColor;
        newButton.fontSize = this.fontSize;
        newButton.labelOffsetX = this.labelOffsetX;
        newButton.labelOffsetY = this.labelOffsetY;
        newButton.mapping = this.mapping;
        newButton.icon = this.icon;
        newButton.iconSize = this.iconSize;
        newButton.showIcon = this.showIcon;
        newButton.group = this.group;

        return newButton;
    }

    getSnapped = (gridSize: number): ConsoleButton => {
        let snappedButton: ConsoleButton = this.clone();
        snappedButton.left = this.snapToGrid(this.left, gridSize);
        snappedButton.right = this.snapToGrid(this.right, gridSize);
        snappedButton.top = this.snapToGrid(this.top, gridSize);
        snappedButton.bottom = this.snapToGrid(this.bottom, gridSize);

        return snappedButton;
    }

    private snapToGrid = (position: number, gridSize: number): number => {
        return Math.round(position / gridSize) * gridSize;
    }

    getWidth = () => {
        return this.right - this.left;
    }

    getHeight = () => {
        return this.bottom - this.top;
    }

    getMiddleX = () => {
        return this.left + (this.getWidth() / 2);
    }

    getMiddleY = () => {
        return this.top + (this.getHeight() / 2);
    }
}