import { GlobalAppState } from "../App";
import Bounds from "./Bounds";
import Point from "./Point";

export class DragPosition extends Bounds  {
    public initialX: number;
    public initialY: number;

    public lastX: number;
    public lastY: number;
    
    public x: number;
    public y: number;

    public lastZoom: number;
    public zoom: number;

    public isReleased: boolean = false;

    constructor(x: number, y: number, zoom: number = 1) {
        super(x, x, y, y);

        this.initialX = x;
        this.initialY = y;

        this.lastX = x;
        this.lastY = y;        

        this.x = x;
        this.y = y;

        this.lastZoom = zoom;
        this.zoom = zoom;
    }

    updatePosition(x: number, y: number, zoom: number = 1) {
        this.lastX = this.x;
        this.lastY = this.y;

        this.x = x;
        this.y = y;

        this.lastZoom = this.zoom;
        this.zoom = zoom;

        this.updateBounds();
    }

    getScaleOffset = (): number => {
        return (this.lastZoom - this.zoom) / this.lastZoom;
    }

    getXOffset = (): number => {
        return (this.x - this.lastX) / GlobalAppState.viewProperties.zoom;
    }

    getYOffset = (): number => {
        return (this.y - this.lastY) / GlobalAppState.viewProperties.zoom;
    }

    private updateBounds = () => {
        this.left = this.getLowerValue(this.initialX, this.x);
        this.right = this.getHigherValue(this.initialX, this.x);

        this.top = this.getLowerValue(this.initialY, this.y);
        this.bottom = this.getHigherValue(this.initialY, this.y);
    }

    getCurrentPoint = (): Point => {
        return new Point(this.x, this.y);
    }


    getStartPoint = (): Point => {
        return new Point(this.initialX, this.initialY);
    }

    private getLowerValue(value1: number, value2: number): number {
        return (value1 < value2) ? value1 : value2;
    }

    private getHigherValue(value1: number, value2: number): number {
        return (value1 >= value2) ? value1 : value2;
    }
}