import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import Axis from '../Enums/Axis';
import ButtonType from '../Enums/ButtonType';
import IconService from '../IconService';
import ConsoleButton from '../Models/ConsoleButton';
import { DragPosition } from '../Models/DragPosition';
import './ButtonPanelButton.css';
import GrabHandle from './GrabHandle';

interface IButtonPanelButtonProps {
    buttonInfo: ConsoleButton;
    showHandles: boolean;
    isLastSelected: boolean;
    gridSize: number;
    snapToGrid: boolean;
    onSizeUpdated: (buttonInfo: ConsoleButton) => void;
    onActivate: (buttonInfo: ConsoleButton) => void;
    onDectivate: (buttonInfo: ConsoleButton) => void;
}

class ButtonPanelButton extends React.Component<IButtonPanelButtonProps> {
    updateTopLeft = (e: DragPosition) => {
        let newButtonInfo: ConsoleButton = this.props.buttonInfo.clone(); 

        newButtonInfo.top = this.props.buttonInfo.top + e.getYOffset();
        newButtonInfo.left = this.props.buttonInfo.left + e.getXOffset();

        if (e.isReleased && this.props.snapToGrid) {
            newButtonInfo = newButtonInfo.getSnapped(this.props.gridSize);
        }

        this.props.onSizeUpdated(newButtonInfo);
    }

    updateTopRight = (e: DragPosition) => {
        let newButtonInfo: ConsoleButton = this.props.buttonInfo.clone();
        newButtonInfo.top = this.props.buttonInfo.top + e.getYOffset();
        newButtonInfo.right = this.props.buttonInfo.right + e.getXOffset();

        if (e.isReleased && this.props.snapToGrid) {
            newButtonInfo = newButtonInfo.getSnapped(this.props.gridSize);
        }

        this.props.onSizeUpdated(newButtonInfo);
    }

    updateBottomLeft = (e: DragPosition) => {
        let newButtonInfo: ConsoleButton = this.props.buttonInfo.clone(); 
        newButtonInfo.bottom = this.props.buttonInfo.bottom + e.getYOffset();
        newButtonInfo.left = this.props.buttonInfo.left + e.getXOffset();

        if (e.isReleased && this.props.snapToGrid) {
            newButtonInfo = newButtonInfo.getSnapped(this.props.gridSize);
        }

        this.props.onSizeUpdated(newButtonInfo);
    }

    updateBottomRight = (e: DragPosition) => {
        let newButtonInfo: ConsoleButton = this.props.buttonInfo.clone();
        newButtonInfo.bottom = this.props.buttonInfo.bottom + e.getYOffset();
        newButtonInfo.right = this.props.buttonInfo.right + e.getXOffset();

        if (e.isReleased && this.props.snapToGrid) {
            newButtonInfo = newButtonInfo.getSnapped(this.props.gridSize);
        }

        this.props.onSizeUpdated(newButtonInfo);
    }

    updateTop = (e: DragPosition) => {
        let newButtonInfo: ConsoleButton = this.props.buttonInfo.clone();
        newButtonInfo.top = this.props.buttonInfo.top + e.getYOffset();

        if (e.isReleased && this.props.snapToGrid) {
            newButtonInfo = newButtonInfo.getSnapped(this.props.gridSize);
        }

        this.props.onSizeUpdated(newButtonInfo);
    }

    updateLeft = (e: DragPosition) => {
        let newButtonInfo: ConsoleButton = this.props.buttonInfo.clone();
        newButtonInfo.left = this.props.buttonInfo.left + e.getXOffset();

        if (e.isReleased && this.props.snapToGrid) {
            newButtonInfo = newButtonInfo.getSnapped(this.props.gridSize);
        }

        this.props.onSizeUpdated(newButtonInfo);
    }

    updateBottom = (e: DragPosition) => {
        let newButtonInfo: ConsoleButton = this.props.buttonInfo.clone(); 
        newButtonInfo.bottom = this.props.buttonInfo.bottom + e.getYOffset();

        if (e.isReleased && this.props.snapToGrid) {
            newButtonInfo = newButtonInfo.getSnapped(this.props.gridSize);
        }

        this.props.onSizeUpdated(newButtonInfo);
    }

    updateRight = (e: DragPosition) => {
        let newButtonInfo: ConsoleButton = this.props.buttonInfo.clone(); 
        newButtonInfo.right += e.getXOffset();

        if (e.isReleased && this.props.snapToGrid) {
            newButtonInfo = newButtonInfo.getSnapped(this.props.gridSize);
        }

        this.props.onSizeUpdated(newButtonInfo);
    }

    updatePosition = (e: DragPosition) => {
        let newButtonInfo: ConsoleButton = this.props.buttonInfo.clone(); 
        newButtonInfo.top += e.getYOffset();
        newButtonInfo.bottom +=  e.getYOffset();
        newButtonInfo.left += e.getXOffset();
        newButtonInfo.right += e.getXOffset();

        if (e.isReleased && this.props.snapToGrid) {
            newButtonInfo = newButtonInfo.getSnapped(this.props.gridSize);
        }

        this.props.onSizeUpdated(newButtonInfo);
    }



    getIcon = () => {
        if (this.props.buttonInfo !== undefined
            && this.props.buttonInfo.showIcon
            && IconService.isValidIcon(this.props.buttonInfo.icon)) {
            let button: ConsoleButton = this.props.snapToGrid
                ? this.props.buttonInfo.getSnapped(this.props.gridSize)
                : this.props.buttonInfo;

            let height: number = button.bottom - button.top;
            let width: number = button.right - button.left;

            // The percent of the total width that each margin should occupy (in decimal, e.g.: .25 = 25%).
            let marginPercentage: number = ((100 - this.props.buttonInfo.iconSize) / 2) / 100;

            let verticalMargin: number = height * marginPercentage;
            let horizontalMargin: number = width * marginPercentage;

            return (
                <FontAwesomeIcon icon={IconService.faName(this.props.buttonInfo?.icon)}
                    style={{
                        position: "absolute",
                        top: verticalMargin,
                        bottom: this.props.buttonInfo.bottom - verticalMargin,
                        left: horizontalMargin,
                        right: this.props.buttonInfo.right - horizontalMargin,
                        height: this.props.buttonInfo.iconSize + "%",
                        width: this.props.buttonInfo.iconSize + "%"
                    }} />
            )
        }
        else {
            return (<></>);
        }
    }

    getGrabhandles = (buttonInfo: ConsoleButton) => {
        if (this.props.showHandles && buttonInfo.selected) {
            return (
                <>
                    <GrabHandle className="corner" 
                        isFocused={this.props.isLastSelected}
                        onDrag={this.updateTopLeft}
                        x={0} 
                        y={0}/>
                    <GrabHandle className="corner" 
                        isFocused={this.props.isLastSelected}
                        onDrag={this.updateTopRight} 
                        x={buttonInfo.getWidth()} 
                        y={0}/>
                    <GrabHandle className="corner" 
                        isFocused={this.props.isLastSelected}
                        onDrag={this.updateBottomLeft} 
                        x={0} 
                        y={buttonInfo.getHeight()}/>
                    <GrabHandle className="corner" 
                        isFocused={this.props.isLastSelected}
                        onDrag={this.updateBottomRight} 
                        x={buttonInfo.getWidth()} 
                        y={buttonInfo.getHeight()}/>

                    <GrabHandle className="center" 
                        isFocused={this.props.isLastSelected}
                        onDrag={this.updatePosition}
                        x={buttonInfo.getWidth()/2} 
                        y={buttonInfo.getHeight()/2}/>

                    <GrabHandle className="side" 
                        isFocused={this.props.isLastSelected}
                        onDrag={this.updateTop}
                        restrictaxis={Axis.y} 
                        x={buttonInfo.getWidth()/2} 
                        y={0}/>
                    <GrabHandle className="side" 
                        isFocused={this.props.isLastSelected}
                        onDrag={this.updateBottom}
                        restrictaxis={Axis.y} 
                        x={buttonInfo.getWidth()/2} 
                        y={buttonInfo.getHeight()}/>
                    <GrabHandle className="side" 
                        isFocused={this.props.isLastSelected}
                        onDrag={this.updateLeft}
                        restrictaxis={Axis.x}
                        x={0} 
                        y={buttonInfo.getHeight()/2}/>
                    <GrabHandle className="side" 
                        isFocused={this.props.isLastSelected}
                        onDrag={this.updateRight} 
                        restrictaxis={Axis.x}
                        x={buttonInfo.getWidth()} 
                        y={buttonInfo.getHeight()/2}/>
                </>   
            );
        }
        else {
            return (<></>)
        }
    }

    buttonActivated = (event: React.MouseEvent<HTMLDivElement, MouseEvent> | React.TouchEvent<HTMLDivElement>) => {
        if ("clientX" in event) {
            event.preventDefault();
        }

        this.props.onActivate(this.props.buttonInfo);
    }

    buttonDeactivated = (event: React.MouseEvent<HTMLDivElement, MouseEvent> | React.TouchEvent<HTMLDivElement>) => {
        event.preventDefault();

        this.props.onDectivate(this.props.buttonInfo);
    }

    render() {
        let classes = "rectangle-button";

        switch (this.props.buttonInfo.type) {
            case ButtonType.Ellipse:
                classes += " ellipse";
                break;
            case ButtonType.Toggle:
                classes += " toggle";
                break;
            default:
                break;
        }

        let snappedButton: ConsoleButton = this.props.buttonInfo.getSnapped(this.props.gridSize);

        return (
            <>
                <div className={classes}
                    style={{
                        top: snappedButton.top + "px",
                        left: snappedButton.left + "px",
                        height: snappedButton.getHeight() + "px",
                        width: snappedButton.getWidth() + "px",
                        zIndex: this.props.buttonInfo.selected ? 100000 : (this.props.buttonInfo.top + this.props.buttonInfo.left) * 10,
                        lineHeight: (this.props.buttonInfo.fontSize + 0.2) + "pt",
                        backgroundColor: this.props.buttonInfo.backgroundColor,
                        color: this.props.buttonInfo.borderColor,
                        borderColor: this.props.buttonInfo.borderColor,
                        fontSize: this.props.buttonInfo.fontSize + "pt",
                    }}
                    onTouchStartCapture={this.buttonActivated}
                    onTouchEndCapture={this.buttonDeactivated}
                    onMouseDown={this.buttonActivated}
                    onMouseUp={this.buttonDeactivated}
                    onContextMenu={(e) => { e.preventDefault(); }} >

                    {this.getIcon()}

                    { /* I hate this table, but it vertically centers text a champ*/}
                    <table style={{
                        position: 'absolute',
                        left: this.props.buttonInfo.labelOffsetX,
                        top: this.props.buttonInfo.labelOffsetY
                    }}>
                        <tbody>
                            <tr>
                                <td dangerouslySetInnerHTML={{ __html: this.props.buttonInfo.text.split("\n").join("<br/>") as string }} />
                            </tr>
                        </tbody>
                    </table>


                    {this.getGrabhandles(snappedButton)}
                </div>
            </>
        )
    }
}

export default ButtonPanelButton;