enum ToolType {
    None,
    Add,
    Pan,
    Select,
    Recenter,
    LockViewport,
    UnlockViewport
}

export default ToolType;