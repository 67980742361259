import LoginHost from "./LoginHost";
import PaymentsService from "./PaymentsService";
import UserServices from "./UserServices";

export default class User {
    public email: string = "";
    public displayName: string = "";
    public image: string = "";
    public credentials: string = "";
    public loginHost: LoginHost = LoginHost.None;

    constructor(user?: User) {
        if (user) {
            this.email = user.email;
            this.displayName = user.displayName;
            this.image = user.image;
            this.credentials = user.credentials;
            this.loginHost = user.loginHost;
        }
    }

    validateAvailableServices = (): Promise<UserServices> => {
        return PaymentsService.validateServices(this);
    }

    clone() {
        return new User({
            email: this.email,
            displayName: this.displayName,
            image: this.image,
            credentials: this.credentials,
            loginHost: this.loginHost
        } as User)
    }
}