import PaymentInfo from "./PaymentInfo";
import User from "./User";
import UserServices from "./UserServices";

export default class PaymentsService {
    public static validateServices = (user: User | undefined): Promise<UserServices> => {
        if (user === undefined || user.email === "") {
            return Promise.resolve(new UserServices());
        }

        return fetch("/PaymentService/GetUserServices", {
            method: "POST",
            body: JSON.stringify(user),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                return response.json();
            })
            .then((responseObject?: UserServices) => {
                if (responseObject === undefined) {
                    return new UserServices()
                }
                else {
                    return responseObject;
                }
            })
            .catch(() => {
                return new UserServices();
            })
    }

    public static buyStandalone = (paymentToken: string, user: User): Promise<UserServices> => {
        if (paymentToken === "" || user.email === "") {
            alert("Missing payment or associated email. Purchase aborted. Please try again.");
            return Promise.resolve(new UserServices());
        }

        return fetch("/PaymentService/BuyStandalone/", {
            method: "POST",
            body: JSON.stringify(new PaymentInfo(paymentToken, user)),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((_) => {
                return PaymentsService.validateServices(user);
            })
            .catch(() => {
                alert("Error posting purchase request. Please refresh the page and try again.");
                return new UserServices();
            })
    }

    public static subscribe = (paymentToken: string, user: User): Promise<UserServices> => {
        if (paymentToken === "" || user.email === "") {
            alert("Missing payment or associated email. Subscription aborted. Please try again.");
            return Promise.resolve(new UserServices());
        }

        return fetch("/PaymentService/Subscribe/", {
            method: "POST",
            body: JSON.stringify(new PaymentInfo(paymentToken, user)),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((_) => {
                return PaymentsService.validateServices(user);
            })
            .catch(() => {
                alert("Error posting subscription request. Please refresh the page and try again.");
                return new UserServices();
            })
    }

    public static resubscribe = (user: User): Promise<UserServices> => {
        return fetch("/PaymentService/Resubscribe/", {
            method: "POST",
            body: JSON.stringify(user),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((_) => {
                return PaymentsService.validateServices(user);
            })
            .catch(() => {
                alert("Error unsubscribing. Please refresh the page and try again. If the issue persists - contact Shiggles LLC support.");
                return new UserServices();
            })
    }

    public static unsubscribe = (user: User): Promise<UserServices> => {
        return fetch("/PaymentService/Unsubscribe/", {
            method: "POST",
            body: JSON.stringify(user),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((_) => {
                return PaymentsService.validateServices(user);
            })
            .catch(() => {
                alert("Error unsubscribing. Please refresh the page and try again. If the issue persists - contact Shiggles LLC support.");
                return new UserServices();
            })
    }
}

