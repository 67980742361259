import * as React from 'react';
import { GlobalAppState } from '../App';
import SubscriptionState from '../Enums/SubscriptionState';
import User from '../Models/User';
import UserServices from '../Models/UserServices';
import './Ad.css';
import './DefaultViewIndicator.css';

interface IDefaultViewIndicatorProps {
    user?: User
}
interface IDefaultViewIndicatorState {
    showAds: boolean;
    width: number;
    height: number;
}

export default class DefaultViewIndicator extends React.Component<IDefaultViewIndicatorProps, IDefaultViewIndicatorState> {
    constructor(props: IDefaultViewIndicatorProps) {
        super(props);

        this.state = {
            height: window.innerHeight,
            width: window.innerWidth,
            showAds: false
        };

        if (this.props.user !== undefined) {
            this.props.user.validateAvailableServices()
                .then((services?: UserServices) => {
                    if (services === undefined) {
                        this.setState({
                            height: window.innerHeight,
                            width: window.innerWidth,
                            showAds: false
                        });

                        return;
                    }

                    switch (services?.subscriptionState) {
                        case SubscriptionState.Suscribed:
                        case SubscriptionState.PendingUnsubscription:
                            this.setState({
                                height: window.innerHeight,
                                width: window.innerWidth,
                                showAds: false
                            });
                            break;
                        default:
                            this.setState({
                                height: window.innerHeight,
                                width: window.innerWidth,
                                showAds: true
                            });
                            break;
                    }
                })
        }
    }


    componentDidMount() {
        window.addEventListener("resize", this.handleRezie);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleRezie);
    }

    handleRezie = (e: any) => {
        this.setState({
            height: e.currentTarget.innerHeight,
            width: e.currentTarget.innerWidth
        })
    }

    render() {
        if (!GlobalAppState.viewProperties.isEditMode) return <></>;

        let adOrientationClass: string = GlobalAppState.viewProperties.isPortraitMode ? "ad-horizontal" : "ad-vertical"; 
        let adSize: number = 150 * GlobalAppState.viewProperties.zoom;

        return <>
            <div className="viewport-indicator"
                style={{
                    left: GlobalAppState.viewProperties.offsetX * GlobalAppState.viewProperties.zoom ,
                    top: GlobalAppState.viewProperties.offsetY * GlobalAppState.viewProperties.zoom,
                    width: this.state.width * GlobalAppState.viewProperties.zoom,
                    height: this.state.height * GlobalAppState.viewProperties.zoom
                }}>
                {!this.state.showAds || GlobalAppState.isStandalone
                    ? <></>
                    : <div className={"viewport-indicator ad " + adOrientationClass}
                        style={{
                            width: GlobalAppState.viewProperties.isPortraitMode ? "100%" : adSize + "px",
                            height: GlobalAppState.viewProperties.isPortraitMode ? adSize + "px" : "100%",
                            fontSize: (18 * GlobalAppState.viewProperties.zoom) + "pt",
                            borderWidth: GlobalAppState.viewProperties.isPortraitMode ? "0 0 medium 0" : "0 medium 0 0"
                        }}>
                        <p>Ad space <br />(<a href="/account">remove ads</a>) </p>
                    </div>
                }
            </div>
        </>
    }
}