enum InputType {
    text,
    number,
    color,
    typeAhead,
    boolean,
    multilineText,
    range
}

export default InputType;