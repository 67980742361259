import * as React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './IconService';
import './index.css';
import Download from './Pages/Download';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Account from './Pages/Account';
import Help from './Pages/Help';
import Consoles from './Pages/Consoles';


const domNode = document.getElementById('root');
const root = createRoot(domNode as Element);

root.render(
    <GoogleOAuthProvider clientId="828863330545-88d0d63chorntaumlnbvqarbbn33robg.apps.googleusercontent.com">
        <Router>
            <Routes>
                <Route path="/" element={<App />}  />
                <Route path="/help" element={<Help />} />
                <Route path="/account" element={<Account />} />
                <Route path="/download" element={<Download />} />
                <Route path="/consoles" element={<Consoles />} />
            </Routes>
        </Router>
    </GoogleOAuthProvider>
);