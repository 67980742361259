import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import ButtonPanelButton from "../Components/ButtonPanelButton";
import ButtonPanelGroup from "../Components/ButtonPanelGroup";
import SimplePageTemplate from "../Components/SimplePageTemplate";
import LocalStorageService from "../Models/LocalStorageService";
import PanelTab from "../Models/PanelTab";
import RemoteConsole from "../Models/RemoteConsole";
import ViewProperties from "../Models/ViewProperties";
import './Consoles.css';

export class ConsolesState {
    consoles: RemoteConsole[] = [];
    isSidebarCollapsed: boolean = false;
}

export default class Consoles extends React.Component<{}, ConsolesState> {
    constructor(props: {}) {
        super(props);

        let consoles: RemoteConsole[] = LocalStorageService.loadSavedConsoles();

        this.state = {
            consoles: consoles,
            isSidebarCollapsed: false
        } as ConsolesState

        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = (_: UIEvent) => {
        // Refiring the render operation to ensure the size calculations are updated
        let newState: ConsolesState = Object.assign({}, this.state);
        this.setState(newState);
    }

    openConsole = (con: RemoteConsole) => {
        LocalStorageService.openConsole(con);

        let viewProps: ViewProperties = LocalStorageService.loadViewProperties() ?? new ViewProperties();

        viewProps.isEditMode = false;

        LocalStorageService.saveViewProperties(viewProps);

        window.location.href = "/";
    }

    deleteConsole = (con: RemoteConsole) => {
        if (window.confirm('"' + con.name + '" will be deleted from local storage. If it has not been exported, the data will be unrecoverable. Continue?')) {

            let remainingConsoles: RemoteConsole[] = LocalStorageService.deleteConsole(con);

            let newState: ConsolesState = Object.assign({}, this.state );
            newState.consoles = remainingConsoles;

            this.setState(newState);

            window.location.href = "/consoles";
        }
    }

    loadConsole = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();

        const reader: FileReader = new FileReader();

        reader.onload = (e) => {
            const text = e.target?.result as string;

            if (text.startsWith("[")) {
                let importedConsoles: RemoteConsole[] = (JSON.parse(text) as RemoteConsole[]).map(x => new RemoteConsole(x));

                for (let console of importedConsoles) {
                    LocalStorageService.saveCurrentConsole(console);
                }
            }
            else {

                let importedConsole: RemoteConsole = new RemoteConsole(JSON.parse(text));

                LocalStorageService.saveCurrentConsole(importedConsole);

                window.location.href = "/";
            }
        };

        if (e.target !== null && e.target.files !== null) {
            reader.readAsText(e.target.files[0]);
        }
    }

    exportConsole = (con: RemoteConsole) => {
        let aTag = document.createElement('a');

        aTag.style.display = "none";
        aTag.href = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(con));
        aTag.download = con.name + ".console";
        aTag.target = "_blank";

        aTag.click();
        aTag.parentNode?.removeChild(aTag);
    }

    exportAllConsoles = () => {
        let aTag = document.createElement('a');

        aTag.style.display = "none";
        aTag.href = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(this.state.consoles));
        aTag.download = "RemoteConsoles-Backup_" + new Date(Date.now()).toISOString() + "_" + this.state.consoles.length + ".console";
        aTag.target = "_blank";

        aTag.click();
        aTag.parentNode?.removeChild(aTag);
    }

    createNewConsole = () => {
        let consoleName: string | null = window.prompt("New console name:");

        if (consoleName !== null) {
            let viewProps = LocalStorageService.loadViewProperties();
            viewProps.isEditMode = true;
            viewProps.currentPropertiesTab = PanelTab.Selected;
            LocalStorageService.saveViewProperties(viewProps);

            let newConsole = new RemoteConsole();
            newConsole.name = consoleName;
            LocalStorageService.saveCurrentConsole(newConsole);
            window.location.href = "/";
        }
    }

    sidebarCollapsed = (isCollapsed: boolean) => {
        let newState: ConsolesState = Object.assign({}, this.state);
        newState.isSidebarCollapsed = isCollapsed;

        this.setState(newState);
    }

    render() {
        let i = 0;

        let consoleElements: React.JSX.Element[] = [];

        let smallerContainer = window.innerWidth < 1200 ? window.innerWidth : 1200;

        let maxWidth = ((this.state.isSidebarCollapsed) ? smallerContainer - 60 : smallerContainer - 147) - 60;

        let zoom = maxWidth / window.innerWidth;

        for (let savedConsole of this.state.consoles) {
            consoleElements.push(
                <div className="button-container-preview"
                    key={i++}
                    title={savedConsole.name}
                    style={{
                        width: window.innerWidth,
                        height: window.innerHeight,
                        zoom: zoom
                    }}>

                    <div className="console-title">
                        {savedConsole.name}
                    </div>

                    {savedConsole.buttons.map(button => (
                        <ButtonPanelButton key={button.key}
                            buttonInfo={button}
                            gridSize={10}
                            showHandles={false}
                            snapToGrid={savedConsole.snapToGrid}
                            isLastSelected={false}
                            onSizeUpdated={(_) => { }}
                            onActivate={(_) => { }}
                            onDectivate={(_) => { }} />
                    ))}

                    {savedConsole.groups.map(group => (
                        <ButtonPanelGroup key={group.key}
                            group={group}
                            onSizeUpdated={(_a, _b) => { }}
                            onSelected={(g_) => { }} />
                    ))}
                </div>
            );

            consoleElements.push(
                <div className="console-controls" key={i++}
                    style={{
                        width: window.innerWidth,
                        zoom: zoom
                    }}>

                    <button onClick={(_) => this.openConsole(savedConsole)}
                        title={"Open " + savedConsole.name}>
                        <FontAwesomeIcon icon="folder-open" />
                        <span>Open</span>
                    </button>

                    <button title={"Export " + savedConsole.name}
                        onClick={(_) => this.exportConsole(savedConsole)}>
                        <FontAwesomeIcon icon="file-export" />
                        <span>Export</span>
                    </button>

                    <button onClick={(_) => this.deleteConsole(savedConsole)}
                        title={"Delete " + savedConsole.name}>
                        <FontAwesomeIcon icon="trash" />
                        <span>Delete</span>
                    </button>
                </div>
            );
        }

        return <>
            <SimplePageTemplate pageTitle="Manage Consoles" pageIcon="right-left" collapseToggled={this.sidebarCollapsed}>
                <div className="consoles-help">
                    <i>
                        Note: Consoles are saved using each browser's local storage. 
                        Clearing your cache or cookies may delete your consoles from local storage.
                    </i>
                    <b>
                        Export <FontAwesomeIcon icon="file-zipper" /> your consoles often to avoid losing them!
                    </b>
                </div>
                <div className="console-tools">
                    <div>
                        <button onClick={this.createNewConsole}>
                            <FontAwesomeIcon icon="file" />
                            <span>New</span>
                        </button>
                    </div>
                    <div>
                        <button onClick={() => { document.getElementById("importConsole")?.click() }} title="Import Console(s)">
                            <FontAwesomeIcon icon="file-import" />
                            <span>Import</span>
                            <input id="importConsole" style={{ display: "none" }}
                                type="file"
                                onChange={this.loadConsole}
                                accept=".console" />
                        </button>

                        <button onClick={this.exportAllConsoles} title="Export All">
                            <FontAwesomeIcon icon="file-zipper" />
                            <span>Export All</span>
                        </button>
                    </div>
                </div>

                {consoleElements}

            </SimplePageTemplate>
        </>
    }
}