import * as React from 'react';
import Draggable from '../Components/Draggable';
import Bounds from './Bounds';
import ConsoleButton from "./ConsoleButton";
import Defaults from "./Defaults";
import { DragPosition } from "./DragPosition";
import GeometryService from './GeometryService';
import Group from "./Group";
import PanelTab from './PanelTab';
import Point from './Point';
import RemoteConsole from './RemoteConsole';
import User from './User';
import ViewProperties from './ViewProperties';

export default class AppState {
    clientKey: string = Defaults.CLIENT_KEY_PLACEHOLDER;
    user?: User;
    currentConsole: RemoteConsole = new RemoteConsole();
    savedConsoles: RemoteConsole[] = [];
    viewProperties: ViewProperties = new ViewProperties();
    isStandalone: boolean = true;

    constructor(appState?: AppState) {
        if (appState !== undefined) {
            this.clientKey = appState.clientKey;
            this.user = new User(appState.user);
            this.currentConsole = new RemoteConsole(appState.currentConsole);
            this.savedConsoles = appState.savedConsoles.map(x=> new RemoteConsole(x));
            this.viewProperties = new ViewProperties(appState.viewProperties);
            this.isStandalone = appState.isStandalone;
        }
    }

    /**
     * Generates a new object containing all of the state's information
     * @returns A copy of the AppState object
     */
    clone = (): AppState => {
        let newState = new AppState();

        newState.clientKey = this.clientKey;
        newState.user = this.user?.clone();
        newState.currentConsole = this.currentConsole.clone();
        newState.savedConsoles = [...this.savedConsoles];
        newState.viewProperties = this.viewProperties.clone();
        newState.isStandalone = this.isStandalone;

        return newState;
    }

    applyZoomAndPan = (e: DragPosition): AppState => {
        let oldZoom = this.viewProperties.zoom;

        let newZoomOffset = e.getScaleOffset() * oldZoom;

        if (this.viewProperties.zoom - e.getScaleOffset() > 0) {
            this.viewProperties.zoom -= newZoomOffset;
        }

        this.viewProperties.offsetX += e.getXOffset();
        this.viewProperties.offsetY += e.getYOffset();

        // Account for previous zoom's scale while zooming 
        // (otherwise it get bigger much faster as you get closer to 0)
        let xScaleOffset = e.x / (oldZoom - newZoomOffset) - e.x / oldZoom;
        let yScaleOffset = e.y / (oldZoom - newZoomOffset) - e.y / oldZoom;

        this.viewProperties.offsetX += xScaleOffset;
        this.viewProperties.offsetY += yScaleOffset;

        return this.clone();
    }

    applySelection = (d: DragPosition): JSX.Element => {
        // Select Buttons
        let selectionBox = <div
            className="selection-box"
            style={{
                left: d.left,
                top: d.top,
                height: d.bottom - d.top,
                width: d.right - d.left
            }}
        />

        let offsetDrag: Bounds = this.applyOffsetToBounds(d);
        let offsetPoint: Point = this.applyOffsetToPoint(d.getCurrentPoint());

        for (let curButton of this.currentConsole.buttons) {
            let inSelection: boolean = GeometryService.doBoundsIntersect(curButton, offsetDrag);

            if (offsetDrag.left !== offsetDrag.right || offsetDrag.top !== offsetDrag.bottom) {
                curButton.selected = inSelection;
            }
            else if (inSelection) {
                // handle point click (toggle selection)
                curButton.selected = !curButton.selected;
                this.viewProperties.lastSelected = curButton.key;
            }
            // Leave any other buttons in their previous selection state
        }

        for (let curGroup of this.currentConsole.groups) {
            let groupBounds = this.getGroupBounds(curGroup.key, true);

            let startsInGroup: boolean = GeometryService.isWithinBounds(this.applyOffsetToPoint(d.getStartPoint()), groupBounds);

            if (startsInGroup) {
                continue;
            }
            else if (GeometryService.doBoundsIntersect(groupBounds, offsetDrag)) {
                this.currentConsole.buttons = this.currentConsole.buttons.map(x => {
                    if (x.group === curGroup.key) {
                        x.selected = true;
                    }
                    return x;
                })
            }
        }

        let closestButton = this.getClosestSelected(offsetPoint);

        this.viewProperties.lastSelected = closestButton === null ? -1 : closestButton.key;

        let selectedButtonsLength: number = this.getSelectedButtons().length;

        if (selectedButtonsLength > 1) {
            this.viewProperties.currentPropertiesTab = PanelTab.Group;
        } else if (selectedButtonsLength === 1) {
            this.viewProperties.currentPropertiesTab = PanelTab.Selected;
        } else {
            this.viewProperties.currentPropertiesTab = PanelTab.Console
        }

        if (d.isReleased) {
            selectionBox = (<></>);
        }

        return selectionBox;
    }

    private getClosestSelected(point: Point): ConsoleButton | null {
        let closest: ConsoleButton | null = null;
        let closestDistance: number | null = null;
        for (let button of this.getSelectedButtons()) {
            if (GeometryService.isWithinBounds(point, button)) {
                return button;
            }

            let distance = Draggable.getDistance(point.x, point.y, button.getMiddleX(), button.getMiddleY());

            if (closestDistance === null || closestDistance > distance) {
                closest = button;
                closestDistance = distance;
            }
        }

        return closest;
    }

    isSelecting = (): boolean => {
        let isSelectionMode = this.viewProperties.isMultiSelectEnabled;

        if (this.viewProperties.shiftHeld) {
            isSelectionMode = !isSelectionMode;
        }

        return isSelectionMode;
    }

    selectButton = (buttonInfo: ConsoleButton): AppState => {
        this.currentConsole.buttons = this.currentConsole.buttons.map((x) => {
            if (x.key === buttonInfo.key) {
                x.selected = this.isSelecting() ? !x.selected : true;

                if (x.selected) {
                    this.viewProperties.lastSelected = x.key;
                }

            } else if (!this.isSelecting()) {
                x.selected = false;
            }
            return x;
        });

        let selectedButtons = this.getSelectedButtons();
        let selectedButtonsLength: number = selectedButtons.length;

        if (selectedButtonsLength > 1) {
            this.viewProperties.currentPropertiesTab = PanelTab.Group;
        } else if (selectedButtonsLength === 1) {
            this.viewProperties.currentPropertiesTab = PanelTab.Selected;
        } else {
            this.viewProperties.currentPropertiesTab = PanelTab.Console;
        }

        return this.clone();
    }

    setLastSelectedButton = (buttonInfo: ConsoleButton): AppState => {
        this.viewProperties.lastSelected = buttonInfo.key;

        return this.clone();
    }

    deselectAll = (): AppState => {
        for (let button of this.currentConsole.buttons) {
            button.selected = false;
        }

        this.viewProperties.currentPropertiesTab = PanelTab.Console;

        this.viewProperties.lastSelected = -1;

        return this.clone();
    }

    getSelectedButton = (): ConsoleButton | null => {
        let selection = this.getSelectedButtons();

        if (selection !== null && selection.length === 1) {
            return selection[0];
        }
        else if (selection !== null && selection.length > 1 && this.viewProperties.lastSelected !== -1 && selection.some(x=>x.key === this.viewProperties.lastSelected)) {
            return selection.filter(x=>x.key === this.viewProperties.lastSelected)[0];
        }
        else {
            return null;
        }
    }

    getSelectedButtons = (): ConsoleButton[] => {
        return this.currentConsole.buttons.filter(x => x.selected);
    }

    generateButtonInCurrentView = (): AppState => {
        let currentKey = 0;

        if (this.currentConsole.buttons) {
            currentKey = this.getNextButtonKey();
        }

        let topOffset: number = Defaults.NEW_BUTTON_OFFSET;
        let leftOffset: number = Defaults.NEW_BUTTON_OFFSET;

        topOffset -= this.viewProperties.offsetY;
        leftOffset -= this.viewProperties.offsetX;

        let newButton: ConsoleButton = new ConsoleButton(currentKey, Math.round(leftOffset), Math.round(topOffset));
        this.currentConsole.buttons.push(newButton);

        this.currentConsole.buttons = this.currentConsole.buttons.map((x) => {
            x.selected = x.key === newButton.key;
            this.viewProperties.lastSelected = x.key;
            return x;
        });

        this.viewProperties.currentPropertiesTab = PanelTab.Selected;

        return this.clone();
    }

    updateButton = (updatedButton: ConsoleButton): AppState => {
        this.viewProperties.lastSelected = updatedButton.key;

        if (updatedButton === null
            || updatedButton.bottom <= updatedButton.top
            || updatedButton.right <= updatedButton.left) {
            return this.clone();
        }

        let newButtons = [];

        for (let i = 0; i < this.currentConsole.buttons.length; i++) {
            let curButton: ConsoleButton = this.currentConsole.buttons[i];

            newButtons.push((curButton.key === updatedButton.key) ? updatedButton : curButton);
        }

        this.currentConsole.buttons = newButtons;

        return this.clone();
    }

    duplicateButton = (buttonInfo: ConsoleButton): AppState => {
        let newButton: ConsoleButton = buttonInfo.clone(this.getNextButtonKey());

        // Offset the new button by 1 grid unit
        newButton.left = newButton.left + this.currentConsole.gridSize;
        newButton.top = newButton.top + this.currentConsole.gridSize;
        newButton.right = newButton.right + this.currentConsole.gridSize;
        newButton.bottom = newButton.bottom + this.currentConsole.gridSize;

        this.currentConsole.buttons.push(newButton);

        this.currentConsole.buttons = this.currentConsole.buttons.map((x) => {
            x.selected = x.key === newButton.key;
            this.viewProperties.lastSelected = x.key;
            return x;
        });
        
        return this.clone();
    }

    deleteButton = (buttonInfo: ConsoleButton): AppState => {
        let deletedButtonIndex: number = this.currentConsole.buttons.findIndex(x => x.key === buttonInfo.key);

        this.currentConsole.buttons.splice(deletedButtonIndex, 1);

        this.viewProperties.currentPropertiesTab = PanelTab.Console;

        return this.clone();
    }

    getNextButtonKey = (): number => {
        if (this.currentConsole.buttons.length === 0) {
            return 0;
        }

        return this.currentConsole.buttons.sort((a, b) => {
            if (a.key < b.key) {
                return 1;
            }
            else if (a.key > b.key) {
                return -1;
            }
            else {
                return 0;
            }
        })[0].key + 1;
    }

    selectGroup = (group: Group): AppState => {
        this.currentConsole.buttons = this.currentConsole.buttons.map((b) => {
            b.selected = b.group === group.key
            return b;
        })

        this.viewProperties.currentPropertiesTab = PanelTab.Group;

        return this.clone();
    }

    getGroupButtons = (group: Group): ConsoleButton[] => {
        let groupButtons: ConsoleButton[] = [];

        if (group.key === -2) {
            return this.getSelectedButtons();
        }

        for (let button of this.currentConsole.buttons) {
            if (button.group === group.key) {
                groupButtons.push(button);
            }
        }

        return groupButtons;
    }


    getSelectedGroup = (): Group | null => {
        let selectedButtons: ConsoleButton[] = this.getSelectedButtons();

        let groupCount = 0;
        let groups: { [groupName: number]: any[] } = {};

        for (let button of selectedButtons) {
            if (button.group === -1) {
                continue;
            }

            if (groups[button.group] === undefined) {
                groups[button.group] = [];
                groupCount++;
            }

            groups[button.group].push(button);
        }

        if (groupCount !== 1) {
            return null;
        }

        for (let groupName in groups) {
            let count: number = groups[groupName].length;

            if (count === this.currentConsole.buttons.filter(x => x.group === selectedButtons[0].group).length) {
                let group = this.currentConsole.groups.filter(x => x.key === parseInt(groupName))[0];

                return group === undefined ? null : group as Group;
            }
        }

        return null;
    }

    createGroup = (): AppState => {
        let newGroup = new Group(this.genNextGroupKey());

        let i: number = 1;

        while (this.currentConsole.groups.filter(x => x.name === newGroup.name).length != 0) {
            newGroup.name = "group-" + i;
            i++;
        }

        let selectedButtons: ConsoleButton[] = this.getSelectedButtons();

        for (let button of this.currentConsole.buttons) {
            if (selectedButtons.some(x => x.key === button.key)) {
                button.group = newGroup.key;
            }
        }

        this.currentConsole.groups = [newGroup, ...this.currentConsole.groups];

        return this.clone();
    }

    getGroupBounds = (groupKey: number, includePadding: boolean = false): Bounds => {

        let group: Group | null;
        let buttons: ConsoleButton[];
        
        if (groupKey === -2) {
            group = null;
            buttons = this.getSelectedButtons();
        }
        else {
            group = this.currentConsole.groups.filter(x => x.key === groupKey)[0];
            buttons = this.getGroupButtons(this.currentConsole.groups.filter(x => x.key === groupKey)[0]);
        }

        let rtn: Bounds = new Bounds(
            buttons[0].left,
            buttons[0].right,
            buttons[0].top,
            buttons[0].bottom
        );

        for (let i = 1; i < buttons.length; i++) {
            let b = buttons[i];

            rtn.left = (b.left < rtn.left) ? b.left : rtn.left;
            rtn.right = (b.right > rtn.right) ? b.right : rtn.right;
            rtn.top = (b.top < rtn.top) ? b.top : rtn.top;
            rtn.bottom = (b.bottom > rtn.bottom) ? b.bottom : rtn.bottom;
        }

        if (includePadding === true && group !== null) {
            rtn.left -= group.padding;
            rtn.right += group.padding;
            rtn.top -= group.padding;
            rtn.bottom += group.padding;
        }

        return rtn;
    }

    updateGroupBounds = (group: Group, newBounds: Bounds): AppState => {
        let oldBounds: Bounds = this.getGroupBounds(group.key);

        let dp: DragPosition;

        if (oldBounds.left !== newBounds.left && oldBounds.right !== newBounds.right
            || oldBounds.top !== newBounds.top && oldBounds.bottom !== newBounds.bottom) {

            let oldHMiddle = (oldBounds.left + oldBounds.right) / 2;
            let oldVMiddle = (oldBounds.top + oldBounds.bottom) / 2;

            let newHMiddle = (oldBounds.left + oldBounds.right) / 2;
            let newVMiddle = (oldBounds.top + oldBounds.bottom) / 2;

            dp = new DragPosition(oldHMiddle, oldVMiddle);
            dp.updatePosition(newHMiddle, newVMiddle);
        }
        else if (oldBounds.left !== newBounds.left) {
            if (oldBounds.top !== newBounds.top) {
                dp = new DragPosition(oldBounds.right, oldBounds.bottom);
                dp.updatePosition(oldBounds.left, oldBounds.top)
                dp.updatePosition(newBounds.left, newBounds.top)
            }
            else if (oldBounds.bottom !== newBounds.bottom) {
                dp = new DragPosition(oldBounds.right, oldBounds.top);
                dp.updatePosition(oldBounds.left, oldBounds.bottom)
                dp.updatePosition(newBounds.left, newBounds.bottom)
            }
            else {
                let vMiddle = (oldBounds.top + oldBounds.bottom) / 2;
                dp = new DragPosition(oldBounds.right, vMiddle);
                dp.updatePosition(oldBounds.left, vMiddle);
                dp.updatePosition(newBounds.left, vMiddle);
            }
        }
        else if (oldBounds.right !== newBounds.right) {
            if (oldBounds.top !== newBounds.top) {
                dp = new DragPosition(oldBounds.left, oldBounds.bottom);
                dp.updatePosition(oldBounds.right, oldBounds.top)
                dp.updatePosition(newBounds.right, newBounds.top)
            }
            else if (oldBounds.bottom !== newBounds.bottom) {
                dp = new DragPosition(oldBounds.left, oldBounds.top);
                dp.updatePosition(oldBounds.right, oldBounds.bottom)
                dp.updatePosition(newBounds.right, newBounds.bottom)
            }
            else {
                let vMiddle = (oldBounds.top + oldBounds.bottom) / 2;
                dp = new DragPosition(oldBounds.left, vMiddle);
                dp.updatePosition(oldBounds.right, vMiddle);
                dp.updatePosition(newBounds.right, vMiddle);
            }
        }
        else if (oldBounds.top != newBounds.top) {
            let hMiddle = (oldBounds.left + oldBounds.right) / 2;
            dp = new DragPosition(hMiddle, oldBounds.bottom);
            dp.updatePosition(hMiddle, oldBounds.top);
            dp.updatePosition(hMiddle, newBounds.top);
        }
        else if (oldBounds.bottom != newBounds.bottom) {
            let hMiddle = (oldBounds.left + oldBounds.right) / 2;
            dp = new DragPosition(hMiddle, oldBounds.top);
            dp.updatePosition(hMiddle, oldBounds.bottom);
            dp.updatePosition(hMiddle, newBounds.bottom);
        }
        else {
            // no change?
            return this.clone();
        }

        let selectedButtons = this.getGroupButtons(group);
        for (let b of selectedButtons) {
            let oldWidth = oldBounds.right - oldBounds.left;
            let oldHeight = oldBounds.bottom - oldBounds.top;

            let newWidth = newBounds.right - newBounds.left;
            let newHeight = newBounds.bottom - newBounds.top;

            // distances from the top or left of the group bounds
            let buttonLeftDistance = b.left - oldBounds.left;
            let buttonRightDistance = b.right - oldBounds.left;
            let buttonTopDistance = b.top - oldBounds.top;
            let buttonBottomDistance = b.bottom - oldBounds.top;

            // calculate new distances from the top or left of group bounds (and re-add bounds offset)
            b.left = ((buttonLeftDistance * newWidth) / oldWidth) + newBounds.left;
            b.right = ((buttonRightDistance * newWidth) / oldWidth) + newBounds.left;

            b.top = ((buttonTopDistance * newHeight) / oldHeight) + newBounds.top;
            b.bottom = ((buttonBottomDistance * newHeight) / oldHeight) + newBounds.top;
        }

        return this.clone();
    }

    updateGroup = (newGroup: Group): AppState => {
        let newGroups: Group[] = [];

        for (let group of this.currentConsole.groups) {
            if (group.key === newGroup.key) {
                newGroups.push(newGroup);
            }
            else {
                newGroups.push(group);
            }
        }

        this.currentConsole.groups = newGroups;

        return this.clone();
    }

    ungroupSelected = (): AppState => {
        let selectedButtons: ConsoleButton[] = this.getSelectedButtons();

        let groupKeys: number[] = [];

        this.currentConsole.buttons = this.currentConsole.buttons.map((b) => {
            if (selectedButtons.some(sb => sb.key === b.key)) {
                groupKeys.push(b.group);
                b.group = -1;
            }

            return b;
        })

        for (let k of groupKeys) {
            if (!this.currentConsole.buttons.some(b => b.group === k)) {
                this.currentConsole.groups = this.currentConsole.groups.filter(g => g.key !== k);
            }
        }

        return this.clone();
    }

    isEntireGroupSelected = (groupKey: number) => {
        let selectedButtons = this.getSelectedButtons();

        // bail out if nothing selected OR any aren't in the same group
        if (selectedButtons.length === 0 || selectedButtons.some(x => x.group === -1 || x.group !== groupKey)) {
            return false;
        }

        // Ensure the selected items make up the whole group (compairing lenght of selection to whole group length)
        return this.currentConsole.buttons.filter(x => selectedButtons[0] !== undefined && x.group === selectedButtons[0].group).length === selectedButtons.length;
    }

    deleteGroup = (doomedGroup: Group): AppState => {
        let selectedButtons: ConsoleButton[] = this.getSelectedButtons();

        let newButtons: ConsoleButton[] = [];
        for (let button of this.currentConsole.buttons) {
            if (!selectedButtons.some(x => x.key === button.key)) {
                newButtons.push(button);
            }
        }
        this.currentConsole.buttons = newButtons;

        let newGroups: Group[] = [];
        for (let group of this.currentConsole.groups) {
            if (group.name !== doomedGroup.name) {
                newGroups.push(group);
            }
        }
        this.currentConsole.groups = newGroups;

        return this.clone();
    }

    genNextGroupKey = (): number => {
        if (this.currentConsole.groups.length === 0) {
            return 0;
        }

        return this.currentConsole.groups.sort((a, b) => {
            if (a.key < b.key) {
                return 1;
            }
            else if (a.key > b.key) {
                return -1;
            }
            else {
                return 0;
            }
        })[0].key + 1;
    }

    updateSelectedTab = (selectedTab: PanelTab): AppState => {
        this.viewProperties.currentPropertiesTab = selectedTab;

        return this.clone();
    }

    updateConsole = (curConsole: RemoteConsole): AppState => {
        this.currentConsole = curConsole;

        return this.clone();
    }

    recenter = (): AppState => {
        this.viewProperties.offsetX = 0;
        this.viewProperties.offsetY = 0;
        this.viewProperties.zoom = 1;

        return this.clone();
    }

    applyOffsetToPoint = (oldPoint: Point) => {
        let point = oldPoint.clone();

        point.x /= this.viewProperties.zoom;
        point.y /= this.viewProperties.zoom;

        point.x -= this.viewProperties.offsetX;
        point.y -= this.viewProperties.offsetY;

        return point;
    }

    applyOffsetToBounds = (oldBounds: Bounds): Bounds => {
        let bounds = oldBounds.clone();
        bounds.left /= this.viewProperties.zoom;
        bounds.right /= this.viewProperties.zoom;

        bounds.top /= this.viewProperties.zoom;
        bounds.bottom /= this.viewProperties.zoom;

        bounds.left -= this.viewProperties.offsetX;
        bounds.right -= this.viewProperties.offsetX;

        bounds.top -= this.viewProperties.offsetY;
        bounds.bottom -= this.viewProperties.offsetY;

        return bounds;
    }
}