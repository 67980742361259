import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import LocalStorageService from "../Models/LocalStorageService";
import LoginService from "../Models/LoginService";
import User from "../Models/User";
import './SimplePageTemplate.css';
import UserBadge from "./UserBadge";
import { IconName } from "@fortawesome/fontawesome-svg-core";

export interface SimplePageTemplateProps {
    pageTitle: string;
    pageIcon?: string;
    children: React.ReactNode;
    collapseToggled?: (isOpen: boolean) => void;
}

export class SimplePageTemplateState {
    isStandalone: boolean = true;
    user?: User;
    isPortrait: boolean = false;
    isCollapsed: boolean = true;
}

export default class SimplePageTemplate extends React.Component<SimplePageTemplateProps, SimplePageTemplateState> {

    constructor(props: SimplePageTemplateProps) {
        super(props);

        let isPortrait: boolean = window.innerHeight > window.innerWidth || window.innerWidth < 620;

        this.state = {
            user: undefined,
            isStandalone: false,
            isPortrait: isPortrait,
            isCollapsed: isPortrait
        };

        if (props.collapseToggled !== undefined) { 
            props.collapseToggled(isPortrait);
        }

        window.addEventListener("resize", this.handleResizeEvent);  

        LoginService.isStandalone()
            .then((response: boolean) => { 
                this.setState({ isStandalone: response, user: undefined });

                if (response === false) {

                    let user: User | undefined = LocalStorageService.loadUser();

                    LoginService.isValid(user)
                        .then((isValid: boolean) => {
                            this.setState({
                                user: isValid ? user : undefined,
                                isStandalone: this.state.isStandalone,
                                isPortrait: window.innerHeight > window.innerWidth || window.innerWidth < 620
                            });
                        });
                }

            })
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResizeEvent);
    }

    handleResizeEvent = (_: UIEvent) => {
        this.setState({
            isStandalone: this.state.isStandalone,
            user: this.state.user,
            isPortrait: window.innerHeight > window.innerWidth || window.innerWidth < 620,
            isCollapsed: this.state.isCollapsed
        })

    }

    activeClass(page: string) {
        if (window.location.pathname === page) {
            return "active";
        }
    }

    toggleCollapse = () => {
        let newState: SimplePageTemplateState = Object.assign({}, this.state);

        newState.isCollapsed = !this.state.isCollapsed;

        if (this.props.collapseToggled !== undefined) { 
            this.props.collapseToggled(newState.isCollapsed);
        }

        this.setState(newState);
    }

    render() {
        return (
            <>
                <div className={"simple-page page-centerer" + ((this.state.isPortrait) ? " portrait": " landscape") + ((this.state.isCollapsed) ? " collapsed" : "")}>
                    <div className="border" />

                    <div className="center-stack">
                        <h1 className="page-header">
                            <a id="back-button" href="/" className="back-button" title="back to console">
                                <FontAwesomeIcon icon="arrow-left" />
                            </a>
                            <span className="icon-title">
                                {this.props.pageIcon
                                    ? <FontAwesomeIcon icon={this.props.pageIcon as IconName} />
                                    : <></>
                                }
                                
                                <span>{this.props.pageTitle}</span>
                            </span>
                            {this.state.isStandalone
                                ? <></>
                                : <UserBadge user={this.state.user} />
                            }
                        </h1>

                        <div className="content-holder">
                            <div className="navigation">
                                <a className={this.activeClass("/consoles")} href="/consoles" >
                                    <FontAwesomeIcon icon="right-left" />
                                    <span>Consoles</span>
                                </a>
                                {this.state.isStandalone
                                    ? <></>
                                    : <>
                                        <a className={this.activeClass("/account")} href="/account">
                                            <FontAwesomeIcon icon="user" />
                                            <span>Account</span>
                                        </a>
                                        <a className={this.activeClass("/download")} href="/download">
                                            <FontAwesomeIcon icon="download" />
                                            <span>Download</span>
                                        </a>
                                    </>
                                }
                                <a className={this.activeClass("/help")} href="/help">
                                    <FontAwesomeIcon icon="question" />
                                    <span>Help</span>
                                </a>
                                <div className="collapse"
                                    onClick={this.toggleCollapse }>
                                    {this.state.isCollapsed
                                        ? <FontAwesomeIcon icon="arrow-right" />
                                        : <FontAwesomeIcon icon="arrow-left" />
                                    }
                                </div>
                            </div>

                            <div className="content">
                                {this.props.children}
                            </div>
                        </div>
                    </div>

                    <div className="border" />
                </div>
            </>
        )
    }

}
