import * as React from 'react';
import { Component, CSSProperties } from 'react';
import { DragPosition } from '../Models/DragPosition';

export interface IDraggableProps {
    onDraggableMove: (dragPosition: DragPosition) => void;
    children?: any;
    className?: string;
    style?: CSSProperties;
    allowWheelEvents?: boolean;
    onBackgroundClick?: () => void;
}

class Draggable extends Component<IDraggableProps> {
    private dragPosition?: DragPosition;
    private usingZoomMode: boolean = false;

    render = () => {
        return (
            <div className={this.props.className}
                style={this.props.style}
                onMouseDown={this.startTrackingDrag}
                onTouchStart={this.startTrackingDrag}
                onMouseOver={this.startTrackingScroll}
                onMouseOut={this.stopTrackingScroll}>
                {this.props.children}
            </div>)
    }

    startTrackingDrag = (e: React.TouchEvent | React.MouseEvent) => {
        (document.activeElement as HTMLElement)?.blur();

        e.stopPropagation();
        if ("clientX" in e) {
            this.dragPosition = new DragPosition(e.clientX, e.clientY, 1);

            document.addEventListener("mousemove", this.fireDragEvent);
            document.addEventListener("mouseup", this.stopTrackingDrag);
        }
        else {
            this.dragPosition = this.getTouchDragPosition(e.touches);

            document.addEventListener("touchmove", this.fireDragEvent);
            document.addEventListener("touchend", this.stopTrackingDrag);
        }
    }

    stopTrackingDrag = (e: MouseEvent | TouchEvent) => {
        if (e instanceof TouchEvent && e.touches.length > 0) {
            // don't sop if a finger is still down
            return;
        }
        
        if (this.dragPosition !== undefined) {
            this.dragPosition.isReleased = true;

            document.removeEventListener("mousemove", this.fireDragEvent);
            document.removeEventListener("mouseup", this.stopTrackingDrag);

            document.removeEventListener("touchmove", this.fireDragEvent);
            document.removeEventListener("touchend", this.stopTrackingDrag);

            if (this.dragPosition.initialX === this.dragPosition.x && this.dragPosition.initialY === this.dragPosition.y
                && (e.target as HTMLDivElement).className.indexOf("button-panel") !== -1
                && this.props.onBackgroundClick !== undefined
            ) {
                // no movement and didn't land on a button - that's a background click
                this.props.onBackgroundClick();
            }

            this.props.onDraggableMove(this.dragPosition);
        }

        e.preventDefault();
    }

    startTrackingScroll = (e: React.MouseEvent) => {
        if (this.props.allowWheelEvents === true) {
            document.removeEventListener("wheel", this.fireDragEvent);
            document.addEventListener("wheel", this.fireDragEvent);
        }
    }

    stopTrackingScroll = (e: React.MouseEvent) => {
        if (this.props.allowWheelEvents === true) {
            document.removeEventListener("wheel", this.fireDragEvent);
        }
    }

    private getTouchDragPosition = (touches: React.TouchList | TouchList): DragPosition => {
        let x = touches[0].clientX;
        let y = touches[0].clientY
        let z = 1;

        if (touches.length === 2) {
            x = (touches[0].clientX + touches[1].clientX) / 2;
            y = (touches[0].clientY + touches[1].clientY) / 2;
            z = Draggable.getTouchDistance(touches[0], touches[1]);
        }

        return new DragPosition(x, y, z);
    }

    fireDragEvent = (e: MouseEvent | TouchEvent | WheelEvent) => {
        if (e instanceof TouchEvent) {

            let newDragPosition: DragPosition = this.getTouchDragPosition(e.touches);

            if (e.touches.length === 2 && this.usingZoomMode === false) {
                this.usingZoomMode = true;
                // ensure there is no "jump" to the new average point when an additional touch is detected
                this.dragPosition = newDragPosition;
            }
            else if (e.touches.length !== 2 && this.usingZoomMode === true) {
                this.usingZoomMode = false;

                // ensure there is no "jump" to the single point, when two fingers are no longer detected
                this.dragPosition = newDragPosition;
            }
            else {
                this.dragPosition?.updatePosition(newDragPosition.x, newDragPosition.y, newDragPosition.zoom);
            }
        }
        else if (this.props.allowWheelEvents === true && e instanceof WheelEvent) {
            if (this.usingZoomMode !== true || this.dragPosition === undefined) {
                this.usingZoomMode = true;
            }

            this.dragPosition = new DragPosition(e.clientX, e.clientY, 1);

            // ensure zoom is always 10% of whatever we're looking at
            let z = this.dragPosition.zoom;
            z = e.deltaY > 0 ? 0.9 : 1.1;

            if (e.clientX > 20)
            this.dragPosition?.updatePosition(e.clientX, e.clientY, z);
        }
        else {
            if (this.usingZoomMode === true || this.dragPosition === undefined) {
                this.usingZoomMode = false;
                this.dragPosition = new DragPosition(e.clientX, e.clientY, 1);
            }

            this.dragPosition?.updatePosition(e.clientX, e.clientY, 1);
        }

        if (this.dragPosition !== undefined) {
            this.props.onDraggableMove(this.dragPosition);
        }

        if (!(e instanceof WheelEvent)) {
            e.preventDefault();
        }

        e.stopPropagation();
    }

    public static getTouchDistance= (point1: React.Touch, point2: React.Touch): number => {
        return Draggable.getDistance(point1.pageX, point1.pageY, point2.pageX, point2.pageY);
    }

    public static getDistance = (point1X: number, point1Y: number, point2X: number, point2Y: number): number => {
        return Math.hypot(
            point2X - point1X,
            point2Y - point1Y);
    }


}

export default Draggable;
