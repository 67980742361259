import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { GlobalAppState } from '../App';
import ToolType from '../Models/ToolType';
import './Toolbar.css';

interface IToolbarProperties {
    onToolSelected: (selectedTool: ToolType) => void;
}

class Toolbar extends React.Component<IToolbarProperties> {
    private getSelectedTool = () => {
        if ((GlobalAppState.viewProperties.isMultiSelectEnabled && !GlobalAppState.viewProperties.shiftHeld)
            || (!GlobalAppState.viewProperties.isMultiSelectEnabled && GlobalAppState.viewProperties.shiftHeld)) {
            return ToolType.Select;
        }
        else {
            return ToolType.Pan;
        }
    }

    private getClassNames = (toolType: ToolType) => {
        let classes = "tool-button";

        if (this.getSelectedTool() === toolType) {
            classes += " toggled";
        }

        return classes;
    }

    render() {
        if (!GlobalAppState.viewProperties.isEditMode) {
            return (
                <>
                    {GlobalAppState.viewProperties.viewLocked
                        ? <div className="toolbar">
                            <button type="button"
                                className={this.getClassNames(ToolType.Recenter)}
                                onClick={e => this.props.onToolSelected(ToolType.LockViewport)}
                                title="unlock viewport">
                                <FontAwesomeIcon icon="lock" />
                            </button>
                        </div>
                        : <div className="toolbar">
                            <button type="button"
                                className={this.getClassNames(ToolType.Recenter)}
                                onClick={e => this.props.onToolSelected(ToolType.UnlockViewport)}
                                title="lock viewport">
                                <FontAwesomeIcon icon="unlock" />
                            </button>
                            <button type="button"
                                className={this.getClassNames(ToolType.Recenter)}
                                onClick={e => this.props.onToolSelected(ToolType.Recenter)}
                                title="recenter the viewport (in case you got lost)">
                                <FontAwesomeIcon icon="arrows-to-eye" />
                            </button>
                        </div>
                    }
                </>
            );
        }

        return(
            <div className="toolbar">
                <button type="button"
                    className="tool-button new"
                    onClick={e => this.props.onToolSelected(ToolType.Add)}
                    title="add a new button">
                    <FontAwesomeIcon icon="plus" />
                </button>
                <span className="tool-group">
                    <button type="button"
                        className={this.getClassNames(ToolType.Pan)}
                        onClick={e => this.props.onToolSelected(ToolType.Pan)}
                        title="enable pan mode">
                        <FontAwesomeIcon icon="up-down-left-right" />
                    </button>

                    <button type="button"
                        className={this.getClassNames(ToolType.Select)}
                        onClick={e => this.props.onToolSelected(ToolType.Select)}
                        title="enable multi-select mode">
                        <FontAwesomeIcon icon="expand" />
                    </button>
                </span>
                {GlobalAppState.viewProperties.viewLocked
                    ? <button type="button"
                        className={this.getClassNames(ToolType.Recenter)}
                        onClick={e => this.props.onToolSelected(ToolType.LockViewport)}
                        title="unlock viewport">
                        <FontAwesomeIcon icon="lock" />
                    </button>
                    : <>
                        <button type="button"
                            className={this.getClassNames(ToolType.Recenter)}
                            onClick={e => this.props.onToolSelected(ToolType.UnlockViewport)}
                            title="lock viewport">
                            <FontAwesomeIcon icon="unlock" />
                        </button>
                        <button type="button"
                            className={this.getClassNames(ToolType.Recenter)}
                            onClick={e => this.props.onToolSelected(ToolType.Recenter)}
                            title="recenter the viewport (in case you got lost)">
                            <FontAwesomeIcon icon="arrows-to-eye" />
                        </button>
                    </>
                }
            </div>
        )
    } 
}

export default Toolbar;