import * as React from "react";
import { GlobalAppState } from "../App";
import ButtonType from "../Enums/ButtonType";
import IconService from "../IconService";
import ConsoleButton from "../Models/ConsoleButton";
import InputType from "../Models/InputType";
import Mappings from "../Models/Mappings";
import PanelInput from "./PanelInput";
import PropertyPanelsTab, { IPropertyPanelsTabProps } from "./PropertiesPanelTab";

interface IButtonPropertiesPanelProps extends IPropertyPanelsTabProps {
    onButtonUpdate: (button: ConsoleButton) => void
    onButtonDelete: (button: ConsoleButton) => void
    onButtonDuplicate: (button: ConsoleButton) => void
}

class ButtonPropertiesPanel extends PropertyPanelsTab<IButtonPropertiesPanelProps> {

    handleDuplicate = () => {
        let button = GlobalAppState.getSelectedButton();
        if (button !== null) {
            this.props.onButtonDuplicate(button);
        }
    }

    handleDelete = () => {
        let button = GlobalAppState.getSelectedButton();
        if (button !== null) {
            this.props.onButtonDelete(button);
        }
    }

    onButtonUpdate = (value: string, isNew: boolean = false) => {
        let button = GlobalAppState.getSelectedButton();

        if (button === null) {
            return;
        }

        let newButton: ConsoleButton = button.clone();

        let form: HTMLFormElement = document.getElementById("button-properties-form") as HTMLFormElement;

        let data: FormData = new FormData(form);

        newButton.text = data.get("label") as string;
        newButton.type = parseInt(data.get("shape") as string) as ButtonType;
        newButton.backgroundColor = data.get("backgroundColor") as string;
        newButton.borderColor = data.get("borderColor") as string;
        newButton.top = parseInt(data.get("top") as string) as number;
        newButton.bottom = parseInt(data.get("bottom") as string);
        newButton.left = parseInt(data.get("left") as string);
        newButton.right = parseInt(data.get("right") as string);
        newButton.fontSize = parseFloat(data.get("fontSize") as string);
        newButton.labelOffsetX = parseInt(data.get("xOffset") as string);
        newButton.labelOffsetY = parseInt(data.get("yOffset") as string);
        newButton.showIcon = data.get("showIcon") as string === "enabled";
        newButton.icon = data.get("icon") as string;

        let newiconSize = parseInt(data.get("iconSize") as string);
        newButton.iconSize = isNaN(newiconSize) ? newButton.iconSize : newiconSize;

        let mappedKeys: string[] = (data.getAll("mappedKey") as string[]);

        if (isNew) {
            mappedKeys.push(value);
        }

        newButton.mapping = mappedKeys;

        this.props.onButtonUpdate(newButton);
    }

    onMappedKeyDelete = (key: number) => {
        let button = GlobalAppState.getSelectedButton();

        if (button === null) {
            return;
        }

        let newButton: ConsoleButton = button.clone();

        let newMapping: string[] = [];
        for (let i = 0; i < newButton.mapping.length; i++) {
            if (i != key) {
                newMapping.push(newButton.mapping[i]);
            }
        }

        newButton.mapping = newMapping;

        this.props.onButtonUpdate(newButton);
    }

    onMappedKeyUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
        let button = GlobalAppState.getSelectedButton();

        if (button === null) {
            return;
        }

        let newButton: ConsoleButton = button.clone();
        newButton.mapping.push(event.target.value);

        this.props.onButtonUpdate(newButton);
    }

    onAddKey = () => {
        let button = GlobalAppState.getSelectedButton();

        if (button !== null) {
            let newButton: ConsoleButton = button.clone();

            newButton.mapping.push("SPACE");

            this.props.onButtonUpdate(newButton);
        }
    }

    render = () => {
        let button = GlobalAppState.getSelectedButton();

        if (button === null) {
            return <></>;
        }

        return (
            <form name="buttonProperties" id="button-properties-form" className="properties-section">
                <h2>Icon</h2>
                <div className="properties-group">
                    <PanelInput inputType={InputType.boolean}
                        name="showIcon"
                        label="Show icon:"
                        hoverTitle="Use the selected icon for the button"
                        value={button.showIcon}
                        onChange={this.onButtonUpdate} />

                    {button.showIcon
                        ? <>
                            <PanelInput inputType={InputType.typeAhead}
                                name="icon"
                                label="Icon:"
                                options={IconService.generateIconOptions()}
                                value={button.icon}
                                onChange={this.onButtonUpdate}
                                isIcon={true} />
                            <PanelInput inputType={InputType.range}
                                name="iconSize"
                                label="Icon Size:"
                                min={1}
                                max={100}
                                value={button.iconSize}
                                numberUnit="%"
                                onChange={this.onButtonUpdate} />
                        </>
                        : <></>}
                </div>

                <h2>Text</h2>
                <div className="properties-group">
                    <PanelInput inputType={InputType.multilineText}
                        name="label"
                        label="Label: "
                        hoverTitle="The text to display on the button"
                        placeholder="Button"
                        value={button.text}
                        onChange={this.onButtonUpdate} />

                    <PanelInput inputType={InputType.number}
                        name="fontSize"
                        label="Font Size: "
                        hoverTitle="The size of the button's font in 'pt' (12pt = ~default font size)"
                        numberUnit="pt"
                        value={button.fontSize}
                        onChange={this.onButtonUpdate} />
                </div>

                <h2>Mapped Key(s)</h2>
                <div className="properties-group">
                    {button.mapping.map((x, i, a) =>
                        <PanelInput key={i}
                            index={i}
                            inputType={InputType.typeAhead}
                            name="mappedKey"
                            label="key:"
                            options={Mappings.getMappingOptions()}
                            value={x}
                            onChange={this.onButtonUpdate}
                            onDelete={this.onMappedKeyDelete} />
                    )}

                    <button type="button" className="add-button" onClick={this.onAddKey} >add key</button>
                </div>

                <h2 className="closed">Color</h2>
                <div className="properties-group closed">
                    <PanelInput inputType={InputType.color}
                        name="backgroundColor"
                        label="Background: "
                        hoverTitle="The color of the button's background"
                        value={button.backgroundColor}
                        onChange={this.onButtonUpdate} />

                    <PanelInput inputType={InputType.color}
                        name="borderColor"
                        label="Text/Border: "
                        hoverTitle="The color of the button's text and border"
                        value={button.borderColor}
                        onChange={this.onButtonUpdate} />
                </div>

                <h2 className="closed">Position</h2>
                <div className=" properties-group closed">
                    <PanelInput inputType={InputType.number}
                        name="top"
                        label="Top: "
                        hoverTitle="The top of the button"
                        numberUnit="px"
                        value={button.top}
                        onChange={this.onButtonUpdate} />

                    <PanelInput inputType={InputType.number}
                        name="bottom"
                        label="Bottom: "
                        hoverTitle="The bottom of the button"
                        numberUnit="px"
                        value={button.bottom}
                        onChange={this.onButtonUpdate} />

                    <PanelInput inputType={InputType.number}
                        name="left"
                        label="Left: "
                        hoverTitle="The left side of the button"
                        numberUnit="px"
                        value={button.left}
                        onChange={this.onButtonUpdate} />

                    <PanelInput inputType={InputType.number}
                        name="right"
                        label="Right: "
                        hoverTitle="The right side of the button"
                        numberUnit="px"
                        value={button.right}
                        onChange={this.onButtonUpdate} />
                </div>
                <h2 className="closed">Label Offset</h2>
                <div className="properties-group closed">
                    <PanelInput inputType={InputType.number}
                        name="xOffset"
                        label="X Offset: "
                        hoverTitle="Describes how off-center the text should be on the left-right axis (negative numbers go left)"
                        numberUnit="px"
                        value={button.labelOffsetX}
                        onChange={this.onButtonUpdate} />

                    <PanelInput inputType={InputType.number}
                        name="yOffset"
                        label="Y Offset: "
                        hoverTitle="Describes how off-center the text should be on the up-down axis (negative numbers go up)"
                        numberUnit="px"
                        value={button.labelOffsetY}
                        onChange={this.onButtonUpdate} />
                </div>

                <h2>Tools</h2>
                <div className=" properties-group">
                    <button type="button" className="duplicate-button" onClick={(_) => this.handleDuplicate()}>duplicate button</button>
                    <button type="button" className="delete-button" onClick={(_) => this.handleDelete()}>delete button</button>
                </div>
            </form>
        )
    }
}

export default ButtonPropertiesPanel