enum VirtualKeyCode {
    None =0,
    VK_A = 65,
    VK_B,
    VK_C,
    K_D,
    VK_E,
    VK_F,
    VK_G,
    VK_H,
    VK_I,
    VK_J,
    VK_K,
    VK_L,
    VK_M,
    VK_N,
    VK_O,
    VK_P,
    VK_Q,
    VK_R,
    VK_S,
    VK_T,
    VK_U,
    VK_V,
    VK_W,
    VK_X,
    VK_Y,
    VK_Z,
    VK_0 = 48,
    VK_1,
    VK_2,
    VK_3,
    VK_4,
    VK_5,
    VK_6,
    VK_7,
    VK_8,
    VK_9,
    OEM_2 = 191,
    OEM_3 = 192,
    OEM_COMMA = 188,
    OEM_MINUS = 189,
    OEM_PERIOD = 190,
    OEM_PLUS = 187,
    OEM_4 = 219,
    OEM_6 = 221,
    OEM_5 = 220,
    SPACE = 32,
    LSHIFT = 160,
    LCONTROL = 162,
    MENU = 18,
    BACK = 8,
    CAPITAL = 20,
    DELETE = 46,
    DOWN = 40,
    END = 35,
    RETURN = 13,
    ESCAPE = 27,
    HELP = 47,
    HOME = 36,
    INSERT = 45,
    LEFT = 37,
    NUMLOCK = 144,
    NEXT = 34,
    PRIOR = 33,
    RIGHT = 39,
    SCROLL = 145,
    TAB = 9,
    UP = 38,
    F1 = 112,
    F2,
    F3,
    F4,
    F5,
    F6,
    F7,
    F8,
    F9,
    F10,
    F11,
    F12,
    F13,
    F14,
    F15,
    F16,
    ADD = 107,
    SUBTRACT = 109,
    MULTIPLY = 106,
    DIVIDE = 111,
    NUMPAD0 = 96,
    NUMPAD1,
    NUMPAD2,
    NUMPAD3,
    NUMPAD4,
    NUMPAD5,
    NUMPAD6,
    NUMPAD7,
    NUMPAD8,
    NUMPAD9
}

export default VirtualKeyCode;