import * as React from "react";
import { GlobalAppState } from "../App";
import InputType from "../Models/InputType";
import RemoteConsole from "../Models/RemoteConsole";
import PanelInput from "./PanelInput";
import PropertyPanelsTab, { IPropertyPanelsTabProps } from "./PropertiesPanelTab";

interface IConsolePropertiesPanelProps extends IPropertyPanelsTabProps {
    onConsolePropertiesUpdate: (console: RemoteConsole) => void;
}

class ConsolePropertiesPanel extends PropertyPanelsTab<IConsolePropertiesPanelProps> {

    onPropertiesUpdate = () => {
        let newProperties: RemoteConsole = GlobalAppState.currentConsole.clone();

        let form: HTMLFormElement = document.getElementById("panelProperties") as HTMLFormElement;
        let data: FormData = new FormData(form);

        newProperties.backgroundColor = data.get("backgroundColor") as string;
        newProperties.showGrid = data.get("showGrid") as string === "enabled";
        newProperties.snapToGrid = data.get("snapToGrid") as string === "enabled";
        newProperties.gridSize = parseInt(data.get("gridSize") as string);

        this.props.onConsolePropertiesUpdate(newProperties);
    }

    render() {
        let displayProperties = GlobalAppState.currentConsole;

        return (
            <div className="properties-section">
                <form name="panelProperties"
                    id="panelProperties">

                    <h2>Color</h2>
                    <div className="properties-group">
                        <PanelInput inputType={InputType.color}
                            name="backgroundColor"
                            label="Background: "
                            hoverTitle="The color of the button area"
                            value={displayProperties.backgroundColor}
                            onChange={this.onPropertiesUpdate} />
                    </div>

                    <h2>Grid</h2>
                    <div className="properties-group">
                        <PanelInput inputType={InputType.boolean}
                            name="showGrid"
                            label="Show grid:"
                            hoverTitle="Display a grid on the button area background"
                            value={displayProperties.showGrid}
                            onChange={this.onPropertiesUpdate} />

                        <PanelInput inputType={InputType.boolean}
                            name="snapToGrid"
                            label="Snap to grid:"
                            hoverTitle="Snap the button edges to the grid"
                            value={displayProperties.snapToGrid}
                            onChange={this.onPropertiesUpdate} />

                        <PanelInput inputType={InputType.number}
                            name="gridSize"
                            label="Grid Size:"
                            hoverTitle="The size of the grid on which buttons will snap"
                            numberUnit="px"
                            value={displayProperties.gridSize}
                            onChange={this.onPropertiesUpdate} />

                        {/*<PanelInput inputType={InputType.color}*/}
                        {/*    name="borderColor"*/}
                        {/*    label="Grid color: "*/}
                        {/*    hoverTitle="The color of the grid lines"*/}
                        {/*    value={this.props.panelProperties?.gridColor}*/}
                        {/*    onChange={this.onButtonUpdate} />*/}
                    </div>
                </form>
            </div>
        )
    }
}

export default ConsolePropertiesPanel