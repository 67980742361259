export default class Bounds {
    constructor(
        public left: number = 0,
        public right: number = 100,
        public top: number = 0,
        public bottom: number = 100
    ) { }

    clone(): Bounds {
        return new Bounds(this.left, this.right, this.top, this.bottom);
    }
}