import * as React from "react";
import { GlobalAppState } from "../App";
// import SubscriptionState from "../Enums/SubscriptionState";
import User from "../Models/User";
// import UserServices from "../Models/UserServices";
import './Ad.css';

export class AdProps {
    user?: User = undefined;
}

export class AdState {
    constructor(
        public isSubscribed: boolean = false
    ) { }
}

export default class Ad extends React.Component<AdProps, AdState> {

    constructor(props: AdProps) {
        super(props);

        this.state = new AdState(true);

        // TODO: Add this back in when ads arrive
        //if (this.props.user !== undefined) {
        //    this.props.user.validateAvailableServices()
        //        .then((services?: UserServices) => {
        //            if (services === undefined) {
        //                this.setState(new AdState(false)); 
        //            }

        //            switch (services?.subscriptionState) {
        //                case SubscriptionState.Suscribed:
        //                case SubscriptionState.PendingUnsubscription:
        //                    this.setState(new AdState(true));
        //                    break;
        //                default:
        //                    this.setState(new AdState(false));
        //                    break;
        //            }
        //        })
        //}
    }

    render() {
        if (this.state.isSubscribed) {
            return <></>;
        }

        if (GlobalAppState.viewProperties.isPortraitMode) {
            /* Remote Consoles - Horizontal (for Portrait) */
            return (
                <div className="ad ad-horizontal">
                    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5819741471407220"
                        crossOrigin="anonymous"></script>
                    <ins className="adsbygoogle"
                        style={{ "display": "block" }}
                        data-ad-client="ca-pub-5819741471407220"
                        data-ad-slot="7348911286"
                        data-ad-format="auto"
                        data-full-width-responsive="true"></ins>
                    <script>
                        (adsbygoogle = window.adsbygoogle || []).push({ });
                    </script>
                </div>
            )
        } else {
            /* Remote Consoles - Vertical (for Landscape) */
            return (<>
                <div className="ad ad-vertical">
                    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5819741471407220"
                        crossOrigin="anonymous"></script>
                    <ins className="adsbygoogle"
                        style={{"display":"block"}}
                        data-ad-client="ca-pub-5819741471407220"
                        data-ad-slot="6181678582"
                        data-ad-format="auto"
                        data-full-width-responsive="true"></ins>
                    <script>
                        (adsbygoogle = window.adsbygoogle || []).push({ });
                    </script>
                </div>
            </>)
        }
    }
}