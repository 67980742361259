import User from "./User";

export default class LoginService {
    public static isValid = (user: User | undefined): Promise<boolean> => {
        if (user === undefined) {
            return Promise.resolve(false);
        }

        return fetch("api/LoginService/IsValid", {
            method: "POST",
            body: JSON.stringify(user),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                return response.text(); 
            })
            .then((responseText) => {
                return responseText.toLowerCase() === "true";
            })
            .catch(() => {
                return false;
            })
    }

    public static isStandalone = (): Promise<boolean> => {

        return fetch("api/LoginService/IsStandalone", {
            method: "GET"
        })
            .then((response) => {
                return response.text();
            })
            .then((responseText) => {
                return responseText.toLowerCase() === "true";
            })
            .catch(() => {
                return false;
            })
    }
}