import * as React from 'react';
import PanelTab from '../Models/PanelTab';

export interface IPropertyPanelsTabProps {
    key: PanelTab;
    header: string;
    isShown: boolean;
}

abstract class PropertyPanelsTab<T extends IPropertyPanelsTabProps> extends React.Component<T> {
    
}

export default PropertyPanelsTab;