import RemoteConsole from "./RemoteConsole";
import User from "./User";
import ViewProperties from "./ViewProperties";

const CURRENT_CONSOLE: string = "current-console";
const SAVED_CONSOLES: string = "saved-consoles";
const USER: string = "user";
const CLIENT_KEY: string = "client-key";
const VIEW_PROPERTIES: string = "view-properties";
export default class LocalStorageService {

    public static loadCurrentConsole(): RemoteConsole {
        let consoleInfo = localStorage.getItem(CURRENT_CONSOLE);

        if (consoleInfo !== null) {
            return new RemoteConsole(JSON.parse(consoleInfo) as RemoteConsole);
        }
        else {
            return new RemoteConsole();
        }
    }

    public static deleteConsole = (consoleToDelete: RemoteConsole): RemoteConsole[] => {
        let consolesString = localStorage.getItem(SAVED_CONSOLES);

        if (consolesString === null) {
            return [];
        }

        let consoles: RemoteConsole[] = (JSON.parse(consolesString) as RemoteConsole[]).map(x => new RemoteConsole(x));

        consoles = consoles.filter(x => x.name !== consoleToDelete.name);

        localStorage.setItem(SAVED_CONSOLES, JSON.stringify(consoles));
        
        return consoles;
    }

    public static openConsole = (curConsole: RemoteConsole) => {
        localStorage.setItem(CURRENT_CONSOLE, JSON.stringify(curConsole));
    }

    public static saveCurrentConsole = (curConsole: RemoteConsole) => {
        localStorage.setItem(CURRENT_CONSOLE, JSON.stringify(curConsole));

        let savedConsoles: RemoteConsole[] = this.loadSavedConsoles();

        if (savedConsoles.some(x => x.name === curConsole.name) && window.confirm("A console with the name '" + curConsole.name + "' already exists. Overwirte?")) {
            let newSavedConsoles = [...savedConsoles.filter(x => x.name !== curConsole.name), curConsole];
            localStorage.setItem(SAVED_CONSOLES, JSON.stringify(newSavedConsoles));
        }
        else if (!savedConsoles.some(x => x.name === curConsole.name)) {
            let newSavedConsoles = [...savedConsoles, curConsole];
            localStorage.setItem(SAVED_CONSOLES, JSON.stringify(newSavedConsoles));
        }
    }

    public static loadSavedConsoles = (): RemoteConsole[] => {
        let savedConsoles: string | null = localStorage.getItem(SAVED_CONSOLES);

        if (savedConsoles !== null) {
            let parsedConsoles: RemoteConsole[] = [];

            let rawConsoles: RemoteConsole[] = JSON.parse(savedConsoles) as RemoteConsole[];

            for (let curConsole of rawConsoles) {
                parsedConsoles.push(new RemoteConsole(curConsole));
            }

            return parsedConsoles;
        }

        return [];
    }

    public static saveViewProperties(viewProps: ViewProperties | undefined) {
        if (viewProps === undefined) {
            localStorage.removeItem(VIEW_PROPERTIES);
        } else {
            localStorage.setItem(VIEW_PROPERTIES, JSON.stringify(viewProps));
        }
    }

    public static loadViewProperties(): ViewProperties {
        let viewProps = localStorage.getItem(VIEW_PROPERTIES);

        if (viewProps !== null) {
            return new ViewProperties(JSON.parse(viewProps) as ViewProperties);
        }
        else {
            return new ViewProperties();
        }
    }

    public static loadUser(): User | undefined {
        let consoleInfo = localStorage.getItem(USER);

        if (consoleInfo !== null) {
            let user: User = new User(JSON.parse(consoleInfo) as User);
            return user.email === "" ? undefined : user;
        }
        else {
            return undefined;
        }
    }

    public static saveUser(user: User | undefined) {
        if (user === undefined) {
            localStorage.removeItem(USER);
        } else {
            localStorage.setItem(USER, JSON.stringify(user));
        }
    }

    public static loadClientKey(): string {
        let clientKey = localStorage.getItem(CLIENT_KEY);

        if (clientKey !== null) {
            return clientKey;
        }
        else {
            return "";
        }
    }

    public static saveClientKey(user: string) {
        localStorage.setItem(CLIENT_KEY, user);
    }

}