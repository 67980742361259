export default class Defaults {
    public static readonly BACKGROUND_COLOR: string = "#333333";
    public static readonly GRID_SIZE: number = 15;
    public static readonly BUTTON_BACKGROUND_COLOR: string = "#222222";
    public static readonly BUTTON_FOREGROUND_COLOR: string = "#ffffff";
    public static readonly BUTTON_FONT_SIZE: number = 24;
    public static readonly BUTTON_SIZE: number = 150;
    public static readonly CLIENT_KEY_PLACEHOLDER: string = "N/A (Disconnected)";
    public static readonly NEW_BUTTON_OFFSET: number = 150;
    public static readonly GRAB_HANDLE_SIZE: number = 16;
}