import * as React from 'react';
import IconService from '../IconService';
import { GlobalAppState } from '../App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './PropertiesPanelToggle.css';

interface IPropertiesPanelToggleProps {
    onPropertiesPanelToggled: () => void;
}

export default class PropertiesPanelToggle extends React.Component<IPropertiesPanelToggleProps> {
    render() {
        let icon: string = "";
        
        if (GlobalAppState.viewProperties.showProperties) {
            if (GlobalAppState.viewProperties.isPortraitMode) {
                icon = "arrow-down";
            }
            else {
                icon = "arrow-right";
            }
        }
        else {
            if (GlobalAppState.viewProperties.isPortraitMode) {
                icon = "arrow-up";
            }
            else {
                icon = "arrow-left";
            }
        }


        return (
            <div className="properties-panel-toggle"
                title="show/hide the properties panel"
                onClick={(_) => this.props.onPropertiesPanelToggled()}>
                <FontAwesomeIcon icon={IconService.faName(icon)} />
            </div>
        )
    }
}