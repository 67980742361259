import ConsoleButton from "./ConsoleButton";
import Defaults from "./Defaults";
import Group from "./Group";
import LocalStorageService from "./LocalStorageService";
import ViewProperties from "./ViewProperties";

export default class RemoteConsole {
    public name: string = "Untitled Console";
    public buttons: ConsoleButton[] = [];
    public groups: Group[] = [];
    public backgroundColor: string = Defaults.BACKGROUND_COLOR;

    public showGrid: boolean = true;
    public snapToGrid: boolean = true;
    public gridSize: number = Defaults.GRID_SIZE;

    constructor(
        remoteConsole?: RemoteConsole
    ) {
        if (remoteConsole !== undefined) {
            this.name = remoteConsole.name;
            this.buttons = remoteConsole.buttons.map(b => ConsoleButton.fromStub(b));
            this.groups = remoteConsole.groups.map(g => Group.fromStub(g));
            this.backgroundColor = remoteConsole.backgroundColor;
            this.showGrid = remoteConsole.showGrid;
            this.snapToGrid = remoteConsole.snapToGrid;
            this.gridSize = remoteConsole.gridSize;
        }
        else {
            let viewProps: ViewProperties = LocalStorageService.loadViewProperties();
            this.buttons.push(new ConsoleButton(0, viewProps.offsetX + 200, viewProps.offsetY + 200));
        }
    }

    clone() {
        return new RemoteConsole({
            name: this.name,
            buttons: this.buttons.map(b => ConsoleButton.fromStub(b)),
            groups: this.groups.map(g => Group.fromStub(g)),
            backgroundColor: this.backgroundColor,
            showGrid: this.showGrid,
            snapToGrid: this.snapToGrid,
            gridSize: this.gridSize
        } as RemoteConsole);
    }

}