import Bounds from "./Bounds";
import Point from "./Point";

export default class GeometryServcie {
	public static isWithinBounds(point: Point, bounds: Bounds): boolean {
		return point.y >= bounds.top && point.y <= bounds.bottom
			&& point.x >= bounds.left && point.x <= bounds.right;
	}

	public static doBoundsIntersect(
		bounds1: Bounds,
		bounds2: Bounds,
		wholeButtonRequired: boolean = false
	) {
		let cornerBounds: boolean[] = [
			this.isWithinBounds(new Point(bounds1.left, bounds1.top), bounds2),
			this.isWithinBounds(new Point(bounds1.left, bounds1.bottom), bounds2),
			this.isWithinBounds(new Point(bounds1.right, bounds1.top), bounds2),
			this.isWithinBounds(new Point(bounds1.right, bounds1.bottom), bounds2)
		];

		if (wholeButtonRequired) {
			return cornerBounds.every((e) => e === true);
		}
		else {
			cornerBounds.push(
				this.isWithinBounds(new Point(bounds2.left, bounds2.top), bounds1),
				this.isWithinBounds(new Point(bounds2.left, bounds2.bottom), bounds1),
				this.isWithinBounds(new Point(bounds2.right, bounds2.top), bounds1),
				this.isWithinBounds(new Point(bounds2.right, bounds2.bottom), bounds1)
			);

			let lineBounds: boolean[] = [
				this.doLineSegmentsIntersect(
					new Point(bounds1.bottom, bounds1.left), new Point(bounds1.bottom, bounds1.right),
					new Point(bounds2.bottom, bounds2.left), new Point(bounds2.top, bounds2.left)
				),
				this.doLineSegmentsIntersect(
					new Point(bounds1.bottom, bounds1.left), new Point(bounds1.bottom, bounds1.right),
					new Point(bounds2.bottom, bounds2.right), new Point(bounds2.top, bounds2.right)
				),
				this.doLineSegmentsIntersect(
					new Point(bounds2.bottom, bounds2.left), new Point(bounds2.bottom, bounds2.right),
					new Point(bounds1.bottom, bounds1.left), new Point(bounds1.top, bounds1.left)
				),
				this.doLineSegmentsIntersect(
					new Point(bounds2.bottom, bounds2.left), new Point(bounds2.bottom, bounds2.right),
					new Point(bounds1.bottom, bounds1.right), new Point(bounds1.top, bounds1.right)
				)
			];

			return lineBounds.some((e) => e === true) || cornerBounds.some((e) => e === true);
		}
	}

	public static doLineSegmentsIntersect(p: Point, p2: Point, q: Point, q2: Point) {
		var r = this.subtractPoints(p2, p);
		var s = this.subtractPoints(q2, q);

		var uNumerator = this.crossProduct(this.subtractPoints(q, p), r);
		var denominator = this.crossProduct(r, s);

		if (uNumerator == 0 && denominator == 0) {
			// They are coLlinear

			// touch? (Are any of the points equal?)
			if (this.equalPoints(p, q) || this.equalPoints(p, q2) || this.equalPoints(p2, q) || this.equalPoints(p2, q2)) {
				return true
			}
			// overlap? (Are all the point differences in either direction the same sign)
			return !this.allEqual((
				(q.x - p.x < 0),
				(q.x - p2.x < 0),
				(q2.x - p.x < 0),
				(q2.x - p2.x < 0))
				|| !this.allEqual(
					(q.y - p.y < 0),
					(q.y - p2.y < 0),
					(q2.y - p.y < 0),
					(q2.y - p2.y < 0))
			);
		}

		if (denominator == 0) {
			// paralell lines
			return false;
		}

		var u = uNumerator / denominator;
		var t = this.crossProduct(this.subtractPoints(q, p), s) / denominator;

		return (t >= 0) && (t <= 1) && (u >= 0) && (u <= 1);
	}

	private static crossProduct(point1: Point, point2: Point): number {
		return point1.x * point2.y - point1.y * point2.x;
	}

	private static subtractPoints(point1: Point, point2: Point): Point {
		var result: Point = new Point(
			point1.x - point2.x,
			point1.y - point2.y
		);

		return result;
	}

	private static equalPoints(point1: Point, point2: Point): Boolean {
		return (point1.x == point2.x) && (point1.y == point2.y)
	}

	private static allEqual(...args: boolean[]): boolean {
		var firstValue = arguments[0],
			i;
		for (i = 1; i < arguments.length; i += 1) {
			if (arguments[i] != firstValue) {
				return false;
			}
		}
		return true;
	}
}