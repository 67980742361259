import ButtonEventType from "../Enums/ButtonEventType";
import VirtualKeyCode from "../Enums/VirtualKeyCode";
import Mappings from "./Mappings";

class ButtonEvent {
    public ClientKey: string = "";
    public ClientTitle: string = "";
    public Event: ButtonEventType = ButtonEventType.PressAndRelease;
    public Mappings: VirtualKeyCode[] = [];

    public constructor(clientKey: string, clientTitle: string, event: ButtonEventType, mapping: string[]) {
        let keyCodes: VirtualKeyCode[] = [];

        for (let keyName of mapping) {
            keyCodes.push(Mappings.KeyMapping[keyName]);
        }
        
        this.ClientKey = clientKey;
        this.ClientTitle = clientTitle;
        this.Event = event; //TODO: set this based on event
        this.Mappings = keyCodes;
    }
}

export default ButtonEvent;