import './GrabHandle.css';
import Axis from '../Enums/Axis';
import Draggable from '../Components/Draggable';
import * as React from 'react';
import { DragPosition } from '../Models/DragPosition';

interface IGrabHandleProps {
    restrictaxis?: Axis;
    className: string;
    x: number;
    y: number;
    isFocused?: boolean;
    onDrag: (e: DragPosition) => void;
}

export class GrabHandle extends React.Component<IGrabHandleProps> {
    public typeName: string = "Grab Handle";

    render() {
        let shape = "circle";

        if (this.props.restrictaxis === Axis.x || this.props.restrictaxis === Axis.y) {
            shape = "box"
        }

        let classes = " grab-handle " + shape;

        if (this.props.className !== undefined) {
            classes += " " + this.props.className;
        }

        return (
            <Draggable className={classes}
                onDraggableMove={this.props.onDrag}
                style={{
                    left: this.props.x,
                    top: this.props.y,
                    opacity: this.props.isFocused === true ? 1 : 0.5
                }}>
            </Draggable>
        );
    }
}

export default GrabHandle;