import Defaults from "./Defaults";

export default class Group {
    key: number = -1;
    name: string = "group";
    showGroup: boolean = true;
    color: string = "#aaaaaa";
    backgroundColor: string = "#666666";
    padding: number = Defaults.GRID_SIZE;

    constructor(key: number) {
        this.key = key;
    }

    public static fromStub(g: Group): Group {
        let newGroup: Group = new Group(g.key);

        newGroup.name = g.name;
        newGroup.showGroup = g.showGroup;
        newGroup.color = g.color;
        newGroup.backgroundColor = g.backgroundColor;
        newGroup.padding = g.padding;

        return newGroup;
    }
}