import { GlobalAppState } from '../App';
import './ConsoleName.css';
import * as React from 'react';

interface IConsoleNameProps {
    onNameChange: (newName: string) => void
}

class ConsoleInfo extends React.Component<IConsoleNameProps> {
    render() {
        return (
            <div className="console-info">
                <input id="console-name"
                    name="consoleName"
                    className="console-name"
                    title="Button Console Title"
                    placeholder="Name your console..."
                    disabled={!GlobalAppState.viewProperties.isEditMode}
                    value={GlobalAppState.currentConsole.name}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        this.props.onNameChange(e.target.value)
                    }}
                />
                <input className="client-key"
                    title="Enter this in the dekstop app to start receving key inputs"
                    placeholder="N/A (Disconnected)"
                    disabled={true}
                    value={GlobalAppState.clientKey} />
            </div>
        )
    }
}

export default ConsoleInfo;