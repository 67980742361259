import { IconName, IconProp, library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

import { faWindows, faApple, faGoogle } from '@fortawesome/free-brands-svg-icons';

library.add(fas);

export default class IconService {
    private static iconOptions: string[] = [];
    public static readonly WindowsIcon = faWindows;
    public static readonly AppleIcon = faApple;
    public static readonly GoogleIcon = faGoogle;

    public static isValidIcon(iconName: string): boolean {
        let options = this.generateIconOptions();

        return iconName !== null
            && iconName !== undefined
            && iconName !== ""
            && options.some(x => x === iconName);
    }

    public static generateIconOptions(): string[] {
        if (this.iconOptions.length === 0) {
            this.iconOptions = [];

            for (let i in fas) {
                if (i.startsWith("fa")) {
                    let matches = i.replace("fa", "")
                        .match(/[A-Z][a-z]+/gm);

                    if (matches) { 
                        this.iconOptions.push(
                            matches.join(" ")
                                .toUpperCase()
                        );
                    }
                }
            }

            this.iconOptions = this.iconOptions
                .filter((item, index) => this.iconOptions.indexOf(item) === index)
                .sort();
        }

        return this.iconOptions
    }

    public static faName(displayName: string | undefined): IconProp {
        if (displayName === undefined || displayName === null) {
            return "" as IconName;
        }

        displayName = displayName
            .toLowerCase()

        while (displayName.indexOf(" ") !== -1) {
            displayName = displayName.replace(" ", "-");
        }

        return displayName as IconName;
    }
}