import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { GlobalAppState } from "../App";
import IconService from "../IconService";
import Group from "../Models/Group";
import InputType from "../Models/InputType";
import './GroupPropertiesPanel.css';
import PanelInput from "./PanelInput";
import PropertyPanelsTab, { IPropertyPanelsTabProps } from "./PropertiesPanelTab";
import ConsoleButton from "../Models/ConsoleButton";

interface IGroupPropertiesPanelProps extends IPropertyPanelsTabProps {
    onGroupUpdate: (group: Group) => void;
    onGroupCreate: () => void;
    onUngroup: (group: Group) => void;
    onGroupDelete: (group: Group) => void;
    onButtonSelect: (button: ConsoleButton) => void;
}

class GroupPropertiesPanel extends PropertyPanelsTab<IGroupPropertiesPanelProps> {
    render() {
        let group = GlobalAppState.getSelectedGroup();

        let selectedButtons: JSX.Element[] = [];

        for (let b of GlobalAppState.getSelectedButtons().sort(this.buttonSort)) {
            selectedButtons.push(
                <div className="list-button"
                    key={b.key}
                    title={b.text}
                    style={{
                        backgroundColor: b.backgroundColor,
                        borderColor: b.borderColor,
                        color: b.borderColor,
                        boxShadow: GlobalAppState.viewProperties.lastSelected === b.key ? "0 0 0 2px rgba(255, 255, 255, 0.75)": ""
                    }}
                    onClick={(_) => this.onButtonSelect(b)}>

                    {b.showIcon
                        ? <span><FontAwesomeIcon icon={IconService.faName(b.icon)} /></span>
                        : <></>
                    }

                    <span>{b.text}</span>
                </div>
            );
        }

        return (
            <form id="group-properties-form" className="properties-section">
                <h2>Buttons</h2>
                <div className="properties-group">
                    <div className="buttons-list">
                        {selectedButtons}
                    </div>
                </div>

                {group !== null
                    ? <>
                        <h2>Group Properties</h2>
                        <div className="properties-group">
                            <PanelInput inputType={InputType.text}
                                label={"Name"}
                                name={"name"}
                                value={group.name}
                                onChange={this.onGroupUpdate} />

                            <PanelInput inputType={InputType.boolean}
                                name="showGroup"
                                label="Show group:"
                                hoverTitle="Show this group when using the console"
                                value={group.showGroup}
                                onChange={this.onGroupUpdate} />

                            {group.showGroup
                                ? <>
                                    <PanelInput inputType={InputType.color}
                                        name="backgroundColor"
                                        label="Background: "
                                        hoverTitle="The color of the group's background"
                                        value={group.backgroundColor}
                                        onChange={this.onGroupUpdate} />

                                    <PanelInput inputType={InputType.color}
                                        name="color"
                                        label="Text/Border: "
                                        hoverTitle="The color of the group's text and border"
                                        value={group.color}
                                        onChange={this.onGroupUpdate} />
                                </>
                                : <></>
                            }
                        </div>
                    </>
                    : <></>
                }

                <h2>Actions</h2>
                <div className="properties-group">
                    {group === null
                        ? <>
                            <button type="button"
                                className="create-button"
                                onClick={(_) => this.props.onGroupCreate()}>
                                Group selected buttons
                            </button>
                        </>
                        : <>
                            <button type="button"
                                className="ungroup-button"
                                onClick={this.onUngroup}>
                                Ungroup
                            </button>
                            <button type="button"
                                className="delete-button"
                                onClick={this.onDelete}>
                                Delete group (inludes contents)
                            </button>
                        </>
                    }
                </div>
            </form>
        )
    }

    onButtonSelect = (button: ConsoleButton) => {
        this.props.onButtonSelect(button);
    }

    onGroupUpdate = (value: string) => {
        let form: HTMLFormElement = document.getElementById("group-properties-form") as HTMLFormElement;
        let data: FormData = new FormData(form);

        let newGroup: Group | null = GlobalAppState.getSelectedGroup();

        if (newGroup === null) {
            newGroup = new Group(GlobalAppState.genNextGroupKey());
        }

        newGroup.name = data.get("name") as string;
        newGroup.showGroup = data.get("showGroup") as string === "enabled";
        newGroup.color = data.get("color") as string;
        newGroup.backgroundColor = data.get("backgroundColor") as string;

        this.props.onGroupUpdate(newGroup);
    }

    onDelete = () => {
        let group = GlobalAppState.getSelectedGroup();

        if (group) {
            this.props.onGroupDelete(group);
        }
    }

    onUngroup = () => {
        let group = GlobalAppState.getSelectedGroup();
        if (group) {
            this.props.onUngroup(group);
        }
    }

    buttonSort = (a: ConsoleButton, b: ConsoleButton): number => {
        if (a.top < b.top) {
            return -1
        }
        else if (a.top > b.top) {
            return 1;
        }
        else if (a.left < b.left) {
            return -1;
        }
        else if (a.left > b.left) {
            return 1;
        }
        else {
            return 0;
        }
    }
}

export default GroupPropertiesPanel