import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import IconService from '../IconService';
import './Menu.css';
import { IconName } from '@fortawesome/fontawesome-svg-core';

interface IMenuProps {
    isEditing: boolean;
    onEditClicked: () => void;
    isStandalone: boolean;
}

interface IMenuState {
    isOpen: boolean
}

class Menu extends React.Component<IMenuProps, IMenuState>  {
    constructor(props: IMenuProps) {
        super(props);
        
        this.state = { isOpen: false };
    }

    componentDidMount() {
        document.addEventListener("click", this.handleClick);
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.handleClick);
    }

    onEdit = () => {
        this.props.onEditClicked();
        this.toggleMenu();
    }

    toggleMenu = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }

    handleClick = (e: MouseEvent) => {
        let target: HTMLDivElement = e.target as HTMLDivElement;

        let menuButton: HTMLDivElement = document.querySelector("#menu-button") as HTMLDivElement;
        let holder: HTMLDivElement = document.querySelector("#menu-items-holder") as HTMLDivElement;

        if (target.className === "menu-button" || menuButton.contains(target) || (holder !== null && !holder.contains(target))) {
            this.toggleMenu();
            e.preventDefault();
        }
    }

    render() {
        return (
            <>
                <button id="menu-button" type="button" className={this.state.isOpen ? "menu-button open" : "menu-button"} title="Menu">
                    <FontAwesomeIcon icon={["fas", this.state.isOpen ? "arrow-left" : "bars"]} color="white" size="2x" />
                </button>

                {this.state.isOpen
                    ? <div id="menu-items-holder" className="menu-items">
                        <a className="menu-item" href="/consoles">
                            <FontAwesomeIcon icon={IconService.faName("RIGHT LEFT")} />
                            <span>Consoles</span>
                        </a>

                        {this.props.isStandalone
                            ? <></>
                            : <>
                                <a className="menu-item"
                                    href="/account">
                                    <FontAwesomeIcon icon="user" />
                                    <span>Account</span>
                                </a>

                                <a className="menu-item"
                                    href="/download">
                                    <FontAwesomeIcon icon="download" />
                                    <span>Receiver</span>
                                </a>
                            </>
                        }

                        <a className="menu-item"
                            href="/help">
                            <FontAwesomeIcon icon="question"/>
                            <span>Help</span>
                        </a>

                        <div className="menu-item" onClick={this.onEdit}>
                            <FontAwesomeIcon icon={(this.props.isEditing ? "save" : "pencil") as IconName} />
                            <span>{this.props.isEditing ? "Save & Run" : "Edit"}</span>
                        </div>
                    </div>
                    : <></>}
            </>
        );
    }
}

export default Menu;