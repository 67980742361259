import * as React from 'react';
import { GlobalAppState } from '../App';
import PanelTab from '../Models/PanelTab';
import './PropertiesPanel.css';
import PropertiesPanelToggle from './PropertiesPanelToggle';

interface IPropertiesPanelProps {
    children: any[];
    onTabChange: (newTab: PanelTab) => void;
    onToggle: () => void;
}

class PropertiesPanel extends React.Component<IPropertiesPanelProps> {
    handleFormClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        let element: HTMLElement = e.target as HTMLElement

        let elementName: string = element.nodeName.toLowerCase();
        if (elementName === "h2") {
            let sibling: any = element.nextElementSibling;

            if (sibling !== null) {
                element.classList.toggle("closed");
                sibling.classList.toggle("closed");
            }
        }
    }

    render() {
        if (!GlobalAppState.viewProperties.isEditMode) {
            return (<></>);
        }

        let classes = "properties-panel";

        if (!GlobalAppState.viewProperties.showProperties) {
            classes += " collapsed";
        }

        // generate the tabs elements
        let tabs: JSX.Element[] = [];

        for (let tab of this.props.children) {
            if (tab.props.isShown) {
                let selectedClass = parseInt(tab.key) === GlobalAppState.viewProperties.currentPropertiesTab ? "selected" : "";

                tabs.push(
                    <h1 id={"properties-panel-tab-" + tab.key}
                        key={tab.key}
                        className={selectedClass}
                        onClick={(_) => this.props.onTabChange(parseInt(tab.key) as PanelTab)}>
                        {tab.props.header}
                    </h1>
                );
            }
        }

        let selectedTab: PanelTab | null = null; 

        for (let tab of this.props.children) {
            if (tab.key !== undefined && parseInt(tab.key) === GlobalAppState.viewProperties.currentPropertiesTab) {
                selectedTab = tab;
            }
        }

        if (selectedTab === null) {
            console.error('Unhandled property tab provided: ' + GlobalAppState.viewProperties.currentPropertiesTab);
            return <></>;
        }

        return (
            <div className={classes}
                onClick={this.handleFormClick}>
                <span className="properties-panel-tabs">
                    {tabs}
                </span>
                {selectedTab}
                <PropertiesPanelToggle onPropertiesPanelToggled={this.props.onToggle} />
            </div>
        )
    }
}

export default PropertiesPanel;