import * as React from "react";
import SimplePageTemplate from "../Components/SimplePageTemplate";
export default class Login extends React.Component<{}> {

    render() {
        return (
            <SimplePageTemplate pageTitle="Help" pageIcon="question">
                <div className="table-of-contents">
                    <h1>Jump to:</h1>
                    <ul>
                        <li>
                            <a href="/help#What">What is RemoteConsoles.com?</a>
                        </li>
                        <li>
                            <a href="/help#Why">What can I use remote consoles for?</a>
                        </li>
                        <li>
                            <a href="/help#Whatnot">What are remote consoles not good for?</a>
                        </li>
                        <li>
                            <a href="/help#Standalone">What is the standalone tool? (coming soon)</a>
                        </li>
                        {/*<li>*/}
                        {/*    <a href="/help#Quickstart">Getting Started</a>*/}
                        {/*</li>*/}
                        <li>
                            <a href="/help#FAQ">FAQ</a>
                        </li>
                        <li>
                            <a href="/help#Support">Support</a>
                        </li>
                    </ul>
                </div>

                <h1 id="What">What is RemoteConsoles.com?</h1>
                <p>This site allows you to create custom button consoles on touch-enabled devices with access to the internet.</p>
                <h2>
                    RemoteConsoles.com is the "sending" piece of a two-part tool.
                </h2>
                <ol>
                    <li>Each button on a console can be assigned one or more keyboard keys.</li>
                    <li><a href="/download">Download</a> the corresponding "Remote Consoles - Receiver" application to a desktop computer and enter the "Key" from the upper-right side of your RemoteConsoles.com console.</li>
                    <li>When a button is pressed on a console, the key events are sent to the "Remote Consoles - Receiver" application where the corresponding keyboard key events are triggered.</li>
                </ol>
                <p>
                    Create as many different consoles as you want and add their client keys to the receiver to receive input from <span title="Or as many as you can afford. Touch-screen devices aren't normally cheap.">mulitple devices</span>.
                </p>

                <h1 id="Why">What can I use remote consoles for?</h1>
                <ul>
                    <li>Remotely control the media playing on your computer from your phone.<br />
                        <i>(e.g., music, movies, clips)</i>
                    </li>
                    <li>Assign complicated hot-key combinations to plain-text buttons</li>
                    <li>
                        Create complimentary button consoles for video games<br />
                        <i>(e.g., flight simulator controls, labeled RPG hotkeys, simple controllers for casual games)</i>
                    </li>
                </ul>

                <h1 id="Whatnot">What are remote consoles not good for?</h1>
                <h2>Realtime events*</h2>
                <p>
                    *The "standalone" version of the "Remote Consoles - Receiver" will be the fastest option as it only uses the local network to send commands.
                    That being said, nothing beats hard-wired controls.
                    Internet-based controls will always lose that battle.
                </p>
                <h2>Analog controls</h2>
                <p>
                    This application set currently supports (most) keys from a standard English QWERTY keyboard. The current options for each key are "down" and "up."
                </p>
                <h2>Making coffee</h2>
                <p>
                    That will land us with a 418 error, and our infrastructure is not ready for that.
                </p>

                <h1 id="Standalone">What is the standalone tool? (coming soon)</h1>
                <p>
                    If you are sick of the ads or don't want to commit to a subscription, the "standalone" tool may be for you.
                </p>
                <p>
                    The "standalone" version of the "Remote Consoles - Receiver" allows you to take the internet out of the equation by making use of your local network (known as an intRAnet).
                </p>
                <ol>
                    <li>After purchasing the standalone, install and run the tool</li>
                    <li>Take note of the current computer's local IP address (displayed in the upper-left side of the application</li>
                    <li>Type that address into your touch-enabled device's browser</li>
                </ol>
                <p>
                    After purchasing, downloading, installing and running the "Remote Consoles - Receiver" standalone application, you will be presented with a local IP address in the upper left panel.
                    On a touch-screen device connected to the same local network, enter the IP address into your <span title="my favorite browsers are the ones in which this website runs without issue">favorite browser</span>.
                </p>

                {/*<h1 id="Quickstart">Getting Started</h1>*/}
                {/*<iframe src="/public/Quickstart/index.html"*/}
                {/*    title="Remote Consoles - Quickstart" />*/}

                <h1 id="FAQ">FAQ</h1>
                <h2 id="FAQ-NoTouchscreen">Can you create consoles on non-touchscreen devices?</h2>
                <p>
                    Yes! but you may instinctually try to poke your screen at some point, so use caution.
                </p>

                <h2>Can you use a console on the same device as the receiver?</h2>
                <p>
                    Technically? Yes... Practially? No...
                </p>
                <p>
                    The receiver will play keyboard events to whatever application has focus.
                    If you were to click or tap on a button on a remote console - the browser window will receive focus and the keyboard event will be played on the remote console itself.
                    This can be used to ensure the receiver is working properly (as the log will display the keyboard events), but it has no other practical use.
                </p>

                <h2 id="FAQ-ReceiverNotReceiving">Why aren't the console buttons working? (nothing appears in the "Remote Consoles - Receiver" log)</h2>
                <h3>Using the free or subscription version of the tool?</h3>
                <ol>
                    <li>Ensure you have <a href="/download" target="_blank">downloaded</a> and installed the "Hosted" version of the "Remote Consoles - Receiver"</li>
                    <li>Navigate to <a href="/" target="_blank">remoteconsoles.com</a> on a <b>different</b> device than the one onto which the "Remote Consoles - Receiver" has been installed</li>
                    <li>Type the "Client key" (in the upper-right of the remote console) into your receiver</li>
                </ol>


                <h3>Using the standalone version of the tool?</h3>
                <ol>
                    <li>Ensure you have <a href="/download" target="_blank">downloaded</a> and installed the "standalone" version of the "Remote Consoles - Receiver"</li>
                    <li>On a <b>different</b> device than the one onto which the "Remote Consoles - Receiver" has been installed, navigate to the local IP address displayed in the upper-left of the receiver (like: <code>http://192.168.x.x</code>)</li>
                    <li>Type the "Client key" (in the upper-right of the remote console) into your receiver</li>
                </ol>

                <h2 id="FAQ-TargetAppNotReceiving">Why aren't the console button's working even though the "Remote Consoles - Reciever" log shows incoming key events?</h2>
                <p>This can happen if the app/window you are trying to control is out of focus. Try some of the following to ensure the target application is in focus.</p>
                <ul>
                    <li>Minimize the Remote Consoles - Receiver application</li>
                    <li>Click the application you want to control with the remote console to bring it to focus</li>
                    <li>Click the specific element on the page you wish to control</li> 
                </ul>
                <p>
                    <i>
                        NOTE: Some applications tightly manage controller / keyboard inputs. These programs may prevent remote console inputs from being received.
                    </i>
                </p>

                <h1 id="Support">Support</h1>
                <p>Please use the <a href="https://shiggles.co/support" target="_blank">Shiggles LLC support form</a> to report any issues or provide suggestions for improvement.</p>

            </SimplePageTemplate>
        )
    }
}