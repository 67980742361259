import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import SimplePageTemplate from "../Components/SimplePageTemplate";
import './Download.css';
import IconService from "../IconService";

export class DownloadProps {
    
}

export default class Download extends React.Component<DownloadProps> {

    render() {
        return (
            <SimplePageTemplate pageTitle="Remote Consoles - Receiver"
                pageIcon="download">

                <h1 className="Hosted">Hosted</h1>
                <p>Use for free - subscribe in your <a href="/account">Account</a> page to remove ads from the web portal.</p>
                <p>Desktop application to receive inputs from RemoteConsoles.com</p>
                <div className="section">
                    <h2>
                        <FontAwesomeIcon icon={IconService.WindowsIcon} /> &nbsp;
                        Windows 10/11
                    </h2>
                    <a href="./Releases/Windows" className="download-link">
                        <FontAwesomeIcon icon="download" /> &nbsp;
                        download v1.0.1
                    </a>

                    <h2>
                        <FontAwesomeIcon icon={IconService.AppleIcon} /> &nbsp;
                        macOS
                    </h2>
                    <p className="disabled-link">
                        {/*<FontAwesomeIcon icon="download"]} /> &nbsp;*/}
                        coming soon
                    </p>
                </div>

                <h1 className="Standalone">Standalone</h1>
                <p><b>Requires product key!</b> Purchase the standalone receiver from your <a href="/account">Account</a> page to receive a product key.</p>
                <p>Desktop application that both hosts an instance of the web interface and receives inputs from devices on the local network. This version does not require an internet connection as it makes use of your local intranet.</p>
                <div className="section">
                    <h2>
                        <FontAwesomeIcon icon={IconService.WindowsIcon} /> &nbsp;
                        Windows 10/11
                    </h2>
                    <a href="./Releases/WindowsStandalone" className="download-link">
                        <FontAwesomeIcon icon="download" /> &nbsp;
                        download v1.0.1
                    </a>

                    <h2>
                        <FontAwesomeIcon icon={IconService.AppleIcon} /> &nbsp;
                        macOS
                    </h2>
                    <p className="disabled-link">
                        {/*<FontAwesomeIcon icon="download"]} /> &nbsp;*/}
                        coming soon
                    </p>
                </div>

                <h2 id="terms">Terms of Use</h2>
                <div className="section">
                    <p>Users may download and install the Remote Consoles - Receiver(s) to any number of personal computers.</p>
                    <p>Re-sale or re-distribution of this software is not permitted. (just tell your friends to come here, it's free)</p>
                    <p>
                        We are committed to providing quality service, however, Shiggles LLC is a very small business with limited resources.
                        As such, all software is provided as-is with no guarantees as to its current or future compatability, usabiltiy, or availability.
                    </p>
                    <p>&copy; 2024 Shiggles LLC</p>
                </div>

            </SimplePageTemplate>
        )
    }
}