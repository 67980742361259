import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import User from '../Models/User';
import './UserBadge.css';

export interface ILoginProps {
    user?: User;
}

class UserBadge extends React.Component<ILoginProps>  {
    render() {
        return (
            <>
                {this.props.user === undefined || this.props.user.email === ""
                    ? (
                        <a className="user-info"
                            href="/account">
                            <FontAwesomeIcon icon="user" color="white" />
                        </a>
                    )
                    : (
                        <a className="user-info"
                            href={"/account"}
                            title={this.props.user.displayName + " (" + this.props.user.email + ")"}>
                            <img src={this.props.user?.image} alt="User profile picture" />
                        </a>
                    )
                 }
            </>
        );
    }
}

export default UserBadge;