import * as React from "react";
import { CreditCard, PaymentForm } from "react-square-web-payments-sdk";
import LocalStorageService from "../Models/LocalStorageService";
import PaymentsService from "../Models/PaymentsService";
import User from "../Models/User";
import UserServices from "../Models/UserServices";
import SubscriptionState from "../Enums/SubscriptionState";
import "./Shop.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface ShopProps {
    user: User;
}

export class ShopState {
    areServicesValidated: boolean = false;
    userServices?: UserServices;
}

export default class Shop extends React.Component<ShopProps, ShopState> {
    constructor(props: ShopProps) {
        super(props);

        let user = LocalStorageService.loadUser(); 

        this.state = {
            areServicesValidated: false,
            userServices: undefined
        } as ShopState;

        if (user !== undefined) {
            user.validateAvailableServices()
                .then((result) => {
                    let newState: ShopState = Object.assign({}, this.state)

                    newState.areServicesValidated = true;
                    newState.userServices = result;

                    this.setState(newState);
                })
        }
    }

    generateSubscriptionOffering = (contents: React.JSX.Element): React.JSX.Element => {
        return (
            <>
                <h2>Enjoy Ad Free! (limited time only)</h2>
                {/*<div className="store-offering disabled" key="subscription">
                    <h2>
                        <span>Remove Ads (Subscription)</span>
                        <span className="price-tag">$1.19 / month</span>
                    </h2>
                    <p>Hides advertisements within the RemoteConsoles.com website when logged-in. Cancel any time.</p>
                    <div className="offering-contents">
                        {contents}
                    </div>
                </div>*/}
            </>
        )
    }

    generateStandaloneOffering = (contents: React.JSX.Element): React.JSX.Element => {
        return (
            <div className="store-offering" key="standalone">
                <h2>
                    <span>Standalone Receiver</span>
                    <span className="price-tag">$11.99</span>
                </h2>
                <p>
                    The "Standalone" version of the "Remote Consoles - Receiver" allows you to take the internet out of the equation by making use of your local network.
                </p>
                <div className="offering-contents">
                    {contents}
                </div>
            </div>
        )
    }    

    render() {
        let offerings: React.JSX.Element[] = [];

        if (!this.state.areServicesValidated) {
            return <h2>Checking current services. Please wait</h2>;
        }

        // Subscription
        if (this.state.userServices?.subscriptionState === SubscriptionState.PendingUnsubscription) {
            offerings.push(this.generateSubscriptionOffering(<>
                <h3><FontAwesomeIcon icon="check" /> Active / Unsubscribed (Available until: {new Date(Date.parse(this.state.userServices.billingDate.toString())).toLocaleDateString()})</h3>
                <button onClick={this.resubscribe}>Resume Subscription</button>
            </>));
        }
        else if (this.state.userServices?.subscriptionState === SubscriptionState.Suscribed) {
            offerings.push(this.generateSubscriptionOffering(<>
                <h3><FontAwesomeIcon icon="check" /> Active / Subscribed (Next billing date: {new Date(Date.parse(this.state.userServices.billingDate?.toString())).toLocaleDateString()})</h3>
                <button onClick={this.unsubscribe}>Unsubscribe</button>
            </>));
        }
        else {
            offerings.push(this.generateSubscriptionOffering(<>
                <h3>Subscribe for $1.19/month</h3>
                {process.env.REACT_APP_SQUARE_APPLICATION_ID !== undefined && process.env.REACT_APP_SQUARE_LOCATION_ID !== undefined
                    ? <>
                        <PaymentForm
                            applicationId={process.env.REACT_APP_SQUARE_APPLICATION_ID}
                            cardTokenizeResponseReceived={(token, verifiedBuyer) => {
                                this.processSubscriptionPayment(token.token);
                            }}
                            locationId={process.env.REACT_APP_SQUARE_LOCATION_ID}
                        >
                            {/*<GooglePay />*/}
                            {/*<ApplePay />*/}
                            <CreditCard />
                        </PaymentForm>
                        <i>
                            Payments processed by SQUARE&trade; on behalf of Shiggles LLC.<br />
                            Purchases will be associated with (and receipts emailed to): <b>{this.props.user.email}</b>
                        </i>
                    </>
                    : <></>
                }
            </>));
        }

        // Standalone
        if (this.state.userServices?.standaloneKey !== "") {
            offerings.push(this.generateStandaloneOffering(<>
                <h3><FontAwesomeIcon icon="check" /> Purchased</h3>
                <p className="product-key">Product Key: <FontAwesomeIcon icon="key"/> <code>{this.state.userServices?.standaloneKey}</code></p>
                <p><a href="/download#standalone">Download the standalone tool</a> to get started.</p>
            </>));
        }
        else {
            offerings.push(this.generateStandaloneOffering(<>
                <h3>Purchase for for $11.99 (one-time fee)</h3>
                {process.env.REACT_APP_SQUARE_APPLICATION_ID !== undefined && process.env.REACT_APP_SQUARE_LOCATION_ID !== undefined
                    ? <>
                        <p>
                            Your product key will appear here after purchase.
                        </p>
                        <PaymentForm
                            applicationId={process.env.REACT_APP_SQUARE_APPLICATION_ID}
                            cardTokenizeResponseReceived={(token, verifiedBuyer) => {
                                this.processStandalonePayment(token.token);
                            }}
                            locationId={process.env.REACT_APP_SQUARE_LOCATION_ID}
                        >
                            {/*<GooglePay />*/}
                            {/*<ApplePay />*/}
                            <CreditCard />
                        </PaymentForm>
                        <i>
                            Payments processed by SQUARE&trade; on behalf of Shiggles LLC.<br />
                            Purchases will be associated with (and receipts emailed to): <b>{this.props.user.email}</b>
                        </i>
                    </>
                    : <></>
                }
            </>));
        }

        return offerings;
    }

    processSubscriptionPayment = (paymentToken: string | undefined) => {
        let user = this.props.user;

        if (paymentToken === undefined || user === undefined || user.email === undefined) {
            return;
        }
        else {
            PaymentsService.subscribe(paymentToken, user);
        }
    }


    processStandalonePayment = (paymentToken: string | undefined) => {
        let user = this.props.user;

        if (paymentToken === undefined || user === undefined || user.email === undefined) {
            return;
        }
        else {
            PaymentsService.buyStandalone(paymentToken, user);
        }
    }

    unsubscribe = () => {
        if (this.props.user === undefined) {
            console.log("User must be defined to make purchaes")
            return;
        }
        this.setState({
            areServicesValidated: false,
            userServices: this.state.userServices
        } as ShopState)

        PaymentsService.unsubscribe(this.props.user)
            .then((userServices: UserServices) => {
                this.setState({
                    areServicesValidated: true,
                    userServices: userServices
                } as ShopState)
            });
    }

    resubscribe = () => {
        if (this.props.user === undefined) {
            console.log("User must be defined to make purchaes")
            return;
        }

        this.setState({
            areServicesValidated: false,
            userServices: this.state.userServices
        } as ShopState)

        PaymentsService.resubscribe(this.props.user)
            .then((userServices: UserServices) => {
                this.setState({
                    areServicesValidated: true,
                    userServices: userServices
                } as ShopState)
            });
    }
}